import { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';

import Box from '@mui/material/Box';
import { PieChart } from '@mui/x-charts/PieChart';

const pieParams = { height: 170, width: 380 };
const palette = ['yellow', 'grey', 'blue'];

const SeasonsWidget = (params) => {

  const dataProvider = useDataProvider();
  const [springSummerCount, setSpringSummerCount] = useState(10);
  const [autumnWinterCount, setAutumnWinterCount] = useState(10);
  const [allYearCount, setAllYearCount] = useState(10);

  useEffect(() => {
    const GetPickupsCount = async () => {
      try {
        const response1 = await dataProvider.getList(
          "products",
          params.springSummer
        );
        setSpringSummerCount(response1?.total);
        const response2 = await dataProvider.getList(
          "products",
          params.autumnWinter
        );
        setAutumnWinterCount(response2?.total);
        const response3 = await dataProvider.getList(
          "products",
          params.allYear
        );
        setAllYearCount(response3?.total);
      } catch (error) {
        console.warn('Error: ', error);
      }
    };

    GetPickupsCount();
  }, [dataProvider, params.allYear, params.autumnWinter, params.springSummer]); // Dependency array to avoid infinite loop

  return (
    <Box flexGrow={1}>
      <PieChart
        series={[{
          data: [{ value: springSummerCount, label: 'Spring/Summer' }, { value: autumnWinterCount, label: 'Autumn/Winter' }, { value: allYearCount, label: 'All Year' }],
          highlightScope: { faded: 'global', highlighted: 'item' },
          faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
        }]}
        colors={palette}
        slotProps={{
        legend: {
            direction: 'column',
            position: { vertical: 'middle', horizontal: 'right' },
            padding: 0,
        }}}
        {...pieParams}
      />
    </Box>
  );
}

export default SeasonsWidget;