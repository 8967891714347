import {
  List,
  Datagrid,
  NumberField,
} from 'react-admin';

export const BagList = props => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <NumberField source="total" />
      <NumberField source="available" />
      <NumberField source="price" />
    </Datagrid>
  </List>
);
