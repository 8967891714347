import styles from './styles.module.scss';

const Widget = ({ title, subtitle, children }) => {
  return (
    <div className={styles.widgetColumn}>
      <div className={styles.widgetTitle}>
        {title}
      </div>
      {children}
      <div className={styles.widgetSubtitle}>
        {subtitle}
      </div>
    </div>
  );
}

export default Widget;