import React from 'react';
import ReactDOM from 'react-dom/client';
import { Admin, Layout, defaultTheme, CustomRoutes } from 'react-admin';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Route } from 'react-router-dom';

import ComingSoon from './ComingSoon';
import { SideMenu } from "src/SideMenu";
import Categories from './resources/Categories';
import SubCategories from './resources/SubCategories';
import Products from './resources/Products';
import Users from './resources/Users';
import Orders from './resources/Orders';
import Bags from './resources/Bags';
import VanSlots from './resources/VanSlots';
import Pickups from './resources/Pickups';
import Translations from './resources/Translations';
import Deliveries from './resources/Deliveries';
import PaymentMethods from './resources/PaymentMethods';
import * as serviceWorker from './services/serviceWorker';
import authProvider from './services/auth';
import dataProvider from './services/data';
import { CirkelAppBar } from './common/CirkelAppBar';
import Dashboard from './Dashboard';

import './styles.scss';
import AllProducts from "src/analytics/allProducts";
import Genders from "src/analytics/genders";
import Sales from './analytics/sales';
import StorageOverview from './storage';

const lightTheme = defaultTheme;
const darkTheme = {
  palette: { mode: 'dark' },
};

const theme = createTheme();
const AdminLayout = (props) => <Layout {...props} appBar={CirkelAppBar} menu={SideMenu}/>;
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Admin
        dashboard={Dashboard}
        dataProvider={dataProvider}
        authProvider={authProvider}
        layout={AdminLayout}
        disableTelemetry
        theme={lightTheme}
        darkTheme={darkTheme}
      >
        {Bags()}
        {Categories()}
        {Deliveries()}
        {Orders()}
        {Pickups()}
        {Products()}
        {SubCategories()}
        {Translations()}
        {Users()}
        {VanSlots()}
        {PaymentMethods()}
        <CustomRoutes>
          <Route path="/comingSoon" element={<ComingSoon />} />
          <Route path="/analytics/allProducts" element={<AllProducts />} />
          <Route path="/analytics/genders" element={<Genders />} />
          <Route path="/sales" element={<Sales />} />
          <Route path="/storage" element={<StorageOverview />} />
        </CustomRoutes>
      </Admin>
    </ThemeProvider>
  </React.StrictMode>
);

serviceWorker.register();
