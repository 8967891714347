import React, { useState, useEffect, Fragment } from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  Button,
  DateTimeInput,
  Edit,
  EditButton,
  FormDataConsumer,
  FormTab,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
  useCreatePath,
  useDataProvider,
  useRecordContext,
} from 'react-admin';

import { Link } from 'react-router-dom';
import FavoriteIcon from '@mui/icons-material/Favorite';
import WatchLaterRoundedIcon from '@mui/icons-material/WatchLaterRounded';
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LocalLaundryServiceRoundedIcon from '@mui/icons-material/LocalLaundryServiceRounded';
import BuildCircleRoundedIcon from '@mui/icons-material/BuildCircleRounded';

import { CopyProductSKU, LowValueDescriptionButton, DescriptionOptions, PickupIdSelector } from 'src/resources/Products/common';

import {
  RichTextInput,
  RichTextInputToolbar,
  LevelSelect,
  FormatButtons,
  AlignmentButtons,
  ListButtons,
  LinkButtons,
  QuoteButtons,
  ClearButtons
} from 'ra-input-rich-text';

import {
  availableProductStatus,
  availableGenders,
  productWeights,
} from 'src/utils';

import ProductAttributes from 'src/common/ProductAttributes';
import { generateSlug } from 'src/resources/Products/common';
import styles from 'src/resources/Products/styles.module.scss';

const SellersShare = (props) => {
  const record = useRecordContext(props);
  if (record.bagPickupId < 1786) {
    if (record.salePrice <= 50) {
      return (record.salePrice * 30 / 100).toFixed(2);
    } else {
      return ((50 * 30 / 100) + ((record.salePrice - 50) * 60 / 100)).toFixed(2);
    }
  } else {
    // New share structure from Pickup #1786:
    if (record.salePrice <= 30) {
      return (record.salePrice * 15 / 100).toFixed(2);
    } else  if (record.salePrice > 30 && record.salePrice <= 50) {
      return ((30 * 15 / 100) + (record.salePrice - 30) * 25 / 100).toFixed(2);
    } else {
      return ((30 * 15 / 100) + (20 * 25 / 100) + (record.salePrice - 50) * 35 / 100).toFixed(2);
    }
  }
}

const Seller = (props) => {
  const record = useRecordContext(props);
  const dataProvider = useDataProvider();
  const createPath = useCreatePath();
  const [seller, setSeller] = useState({});

  useEffect(() => {
    (async (record) => {
      let res = '';
      try {
        res = await dataProvider.getOne('pickups', { id: record.bagPickupId });
        res = await dataProvider.getOne('users', { id: res.data.userAddressSnapshot.userId });
      } catch (error) {
        console.warn('Error: ', error);
      }
      setSeller(seller => {
        return res?.data
      })
    })(record)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record])

  const userPath = createPath({ resource: 'users', type: 'edit', id: seller?.id });

  return (
    <table>
      <thead>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Phone number</th>
          <th>Email Address</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{seller?.firstName}</td>
          <td>{seller?.lastName}</td>
          <td>{seller?.phoneNumber}</td>
          <td>{seller?.email}</td>
          <td>
          <EditButton component={Link} variant="contained" to={userPath} label="Edit" />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

const GetImageName = () => {
  const record = useRecordContext();

  return (
    <div className={styles.imageTitle}>
      {record?.data?.substring(record?.data?.lastIndexOf("/") + 1)}
    </div>
  );
};

const ReceiptText = () => {
  const record = useRecordContext();

  return record?.code + " - " + record?.name;
};

const CopyReceiptText = () => {
  const receiptText = ReceiptText();

  return (
    <Button
      style={{ minWidth: '32px' }}
      sx={{ "& .MuiButton-startIcon": { marginRight: "0px" }}}
      onClick={() => navigator.clipboard.writeText(receiptText)}
    >
      <ContentCopyIcon />
    </Button>
  );
};

const EditToolbar = props => (
  <Toolbar {...props} >
    <SaveButton />
    {/* <DeleteButton /> */}
  </Toolbar>
);

const CopyUniqueProductID = () => {
  const record = useRecordContext();

  return (
    <Button
      style={{ minWidth: '32px' }}
      sx={{ "& .MuiButton-startIcon": { marginRight: "0px" }}}
      onClick={() => navigator.clipboard.writeText(record && record.id)}
    >
      <ContentCopyIcon />
    </Button>
  );
}

export const ProductEdit = props => {
  const [slug, setSlug] = useState('');
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [newFilesTableVisible, setNewFilesTableVisible] = useState(false);

  const imageInputOptions = {
    onDrop: () => setNewFilesTableVisible(true),
    onDropAccepted: (newAcceptedFiles) => setAcceptedFiles(acceptedFiles.concat(newAcceptedFiles)),
    onDropRejected: (newRejectedFiles) => setRejectedFiles(rejectedFiles.concat(newRejectedFiles))
  };

  return (
    <Edit {...props} redirect={false}>
      <TabbedForm warnWhenUnsavedChanges toolbar={<EditToolbar />}>
        <FormTab label="Essentials">
          <div className="helper-text">Unique Product ID::</div>
            <div>
              <CopyUniqueProductID />
              <TextField source="id" />
            </div>
          <div className="helper-text">SKU:</div>
            <div>
              <CopyProductSKU />
              <TextField source="code" />
            </div>
          <div className="helper-text">* Receipt Text (for use in cashier):</div>
          <div>
            <CopyReceiptText />
            <ReceiptText />
          </div>
          <TextInput source="name" id="productName" validate={[required()]} fullWidth onChange={(e) => generateSlug(e.target.value, setSlug)} />
          <div className="helper-text">* User-friendly name that will be displayed on the Product Page.</div>
          <div className="helper-text">* Follow format: <b>'Brand name' + 'material' + 'item name' + 'color'</b>. Example: "Adidas jacket blue" or "Lacoste leather hat brown".</div>
          <div className="helper-text">* If no brand available enter <b>No name</b>. Example: "No name blue jacket".</div>
          <div className="helper-text">* For books follow format: <b>'Author name' + 'book title'</b>. Example: "Elliot Whatever - The Blue Sky"</div>
          <LowValueDescriptionButton />
          <div>Proposed slug: (Auto-generated)
            <br />
            <b id='generatedSlug'>{slug}</b>
            {slug !== '' && (
              <Button
                onClick={() => {
                  const value = document.getElementById('generatedSlug').textContent;
                  navigator.clipboard.writeText(value);
                }}
                label='✂️Copy'
              />
            )}
          </div>
          <TextInput source="slug" fullWidth validate={[required()]} />
          <div className="helper-text">* Same as name but <b>NO capital letters, special characters or spaces</b>. Example: "adidas-blue-jacket".</div>
          <PickupIdSelector />
          <div className="helper-text">* Choose the bag with id "1" for items with no seller (Sold by Cirkel).</div>
          <div className="helper-text">* You can search for older Pickup IDs as well.</div>
          <div className={styles.priceContainer}>
            <NumberInput label={"Price"} source="price" />
            <NumberInput label={"Sale Price"} source="salePrice" />
            <NumberInput label={"Suggester Price"} source="suggestedPrice" />
          </div>
          <div className="helper-text">* Price: The price that Cirkel suggests the Sellers to list the item for.</div>
          <div className="helper-text">* Sale Price: The price that the item will go for sale (either with or without the sellers configuration).</div>
          <div className="helper-text">* Suggester Price: The price that Cirkel may choose to discount the item with.</div>
          <div>
            <FormDataConsumer>
              {({ formData }) => {
                return (
                  <SelectInput
                    label="Status"
                    source="productStatusName"
                    validate={[required()]}
                    choices={availableProductStatus}
                    // disabled={formData.productStatusName === 'SOLD' && !statusUnlocked}
                  />
                );
              }}
            </FormDataConsumer>
          </div>
        </FormTab>
        <FormTab label="Description">
          <DescriptionOptions />
          <RichTextInput
            id='itemDescription'
            source="description"
            fullWidth
            toolbar={
              <RichTextInputToolbar>
                <LevelSelect size={'large'} />
                <FormatButtons size={'large'} />
                <AlignmentButtons size={'large'} />
                <ListButtons size={'large'} />
                <LinkButtons size={'large'} />
                <QuoteButtons size={'large'} />
                <ClearButtons size={'large'} />
              </RichTextInputToolbar>
            }
          />
          <div className="helper-text">* This description will be used on the Product page to inform viewers of additional characteristics.</div>
        </FormTab>
        <FormTab label="Categorization">
          <ReferenceInput
            label="Category"
            source="categoryId"
            reference="categories"
            sort={{ field: 'categoryName', order: 'ASC' }}
            filterToQuery={searchText => ({ categoryName: searchText })}
            alwaysOn
          >
            <AutocompleteInput optionText="categoryName" validate={[required()]} />
          </ReferenceInput>
          <div className="helper-text">* The category of an item can not be edited after it has been created.</div>
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <ReferenceArrayInput
                source="subcategoriesIds"
                reference="subcategories"
                filter={{ categoryId: formData && formData.categoryId }}
              >
                <SelectArrayInput optionText="subcategoryName" validate={[required()]} />
              </ReferenceArrayInput>
            )}
          </FormDataConsumer>
          <div className="helper-text">* You can choose more than one Sub-Categories that belong to the above selected Category.</div>
          <SelectInput label="Gender" source="gender" choices={availableGenders} validate={[required()]} />
          <div className="helper-text">* For books you pick either "Unisex" or "Kid".</div>
          <div>
            <BooleanInput label="Featured" source="featured" options={{ checkedIcon: <FavoriteIcon /> }} />
            <BooleanInput label="Last Call" source="lastCall" options={{ checkedIcon: <WatchLaterRoundedIcon /> }} />
          </div>
        </FormTab>
        <FormTab label="Attributes">
          <FormDataConsumer>
            {({ formData }) => {
              return <ProductAttributes formData={formData} />
            }}
          </FormDataConsumer>
        </FormTab>
        <FormTab label="Cleaning">
          <BooleanInput label="Cleaned" source="cleaned" options={{ checkedIcon: <LocalLaundryServiceRoundedIcon /> }} />
          <BooleanInput label="Fixed" source="fixed" options={{ checkedIcon: <BuildCircleRoundedIcon /> }} />
        </FormTab>
        <FormTab label="Images">
          <ImageInput source="images" accept="image/jpeg" multiple maxSize={500000} options={imageInputOptions}>
            <Fragment>
              <GetImageName />
              <ImageField sx={{ '& .RaImageField-image': { width: 335, height: 500, objectFit: 'contain' } }} source="data" />
            </Fragment>
          </ImageInput>
          <div className="helper-text">* Only .jpeg</div>
          <div className="helper-text">** Maximum allowed size 500KB</div>
          <div className="helper-text">*** Featured image must be named 1.jpeg</div>
          <div className="helper-text">**** Recommended image ratio 0.67. This means all of the above resolutions:</div>
          <div className="helper-text">• Width x Height (in pixels)</div>
          <div className="helper-text">• 670 x 1000</div>
          <div className="helper-text">• 1005 x 1500</div>
          <div className="helper-text">• 1340 x 2000</div>
          <div className="helper-text">• 2010 x 3000</div>
          <div className="helper-text">• 2680 x 4000</div>
          {newFilesTableVisible &&
          <table className={styles.fileTable}>
            <col></col>
            <colgroup span="7"></colgroup>
            <th colspan="7">New Images to be uploaded</th>
            <tr>
              <th>#</th>
              <th>Status</th>
              <th>File name</th>
              <th>Size (KB)</th>
              <th>Rejection Reason</th>
              <th>Type</th>
              <th>Last Modified</th>
            </tr>
            {acceptedFiles.map((acceptedFile, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>🟢</td>
                  <td>{acceptedFile.name}</td>
                  <td>{acceptedFile.size}</td>
                  <td> - - </td>
                  <td>{acceptedFile.type}</td>
                  <td>{acceptedFile.lastModified}</td>
                </tr>
              );
            })}
            {rejectedFiles.map((rejectedFile, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>🔴</td>
                  <td>{rejectedFile.file.name}</td>
                  <td>{rejectedFile.size}</td>
                  <td>{rejectedFile.errors.map((error) => <li>{ error.message }</li>)}</td>
                  <td>{rejectedFile.file.type}</td>
                  <td>{rejectedFile.lastModified}</td>
                </tr>
              );
            })}
          </table>}
        </FormTab>
        <FormTab label="Administration">
          <SelectInput label="Average weight" source="weight" choices={productWeights} optionText="label" optionValue="value" />
          <div className="helper-text">* The value of the product to be used for calculating shipping costs.</div>
          <TextInput label='Storage Position' source='storagePosition' />
          <NumberInput label='Quantity' source="quantity" defaultValue={1} />
          <div className="helper-text">* Only for stack of items that will go for donation or recycling!</div>
          <div className="helper-text">* Leave at 1 if item will go for sale.</div>
          <RichTextInput
          id='itemStatusDescription'
          source="statusDescription"
          multiline="true"
          fullWidth
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect size={'large'} />
              <FormatButtons size={'large'} />
              <AlignmentButtons size={'large'} />
              <ListButtons size={'large'} />
              <LinkButtons size={'large'} />
              <QuoteButtons size={'large'} />
              <ClearButtons size={'large'} />
            </RichTextInputToolbar>
          }
        />
          <div className="helper-text">* This info is for internal communication only and will not be visible on the site</div>
          <DateTimeInput label='Creation Date:' source='createdAt' locales="el-GR" disabled />
          <div className="helper-text">* This is the date the item was created in the system.</div>
          <DateTimeInput label='Last Update Date:' source='updatedAt' locales="el-GR" disabled />
          <div className="helper-text">* This is the date the last date someone made an edit to this item.</div>
          <DateTimeInput label='Publish Date:' source='publishedAt' locales="el-GR" />
          <div className="helper-text">* This is the first time the item went live for sale on our website.</div>
          <DateTimeInput label='Expiration Date:' source="expirationDate" locales="el-GR" />
          <DateTimeInput label='Sold At:' source='soldAt' locales="el-GR" />
          <div className="helper-text">* This is the the time the item SOLD.</div>
        </FormTab>
        <FormTab label="Seller">
          <div>Sellers share: <strong><SellersShare /> Euro</strong></div>
          <BooleanInput label="Seller Share Paid" source="sellerSharePaid" options={{ checkedIcon: <PaidRoundedIcon /> }} />
          <Seller />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
};
