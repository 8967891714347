
import {
  useDataProvider,
  useRecordContext
} from 'react-admin';
import React, {
  useState,
  useEffect,
  Fragment
} from 'react';
import {
  ChipField,
  Datagrid,
  DateTimeInput,
  EditButton,
  Filter,
  FunctionField,
  List,
  Resource,
  SelectInput,
  TextField
} from 'react-admin';
import { Link } from 'react-router-dom';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import classNames from 'classnames';
import {  formatDateMinus2Hours } from '../../utils';
import { AdminPagination } from '../../common/AdminPagination';

import styles from './styles.module.scss';

const DeliveriesFilter = (props) => (
  <Filter {...props}>
      <DateTimeInput source="startDate" locales="el-GR" alwaysOn />
      <DateTimeInput source="endDate" locales="el-GR" alwaysOn />
      <SelectInput label="Type" source="type" alwaysOn choices={[
        { id: 'BAG_PICKUP', name: 'Bag Pickup' },
        { id: 'BAG_SHIPMENT', name: 'Bag Order' },
      ]} />
  </Filter>
);

const GetGoogleMapsLink = ({ address }) => {
  const mapsLink = "https://maps.google.com/?q=" + address;

  return (
    <a href={mapsLink}>
      <img className={styles.googleMapsIcon} alt="googleMapsIcon" width="30" height="30"></img>
    </a>
  );
}

const GetStatus = (props) => {
  const record = useRecordContext(props);
  const dataProvider = useDataProvider();
  const [status, setStatus] = useState({})

  useEffect(() => {
    (async (record) => {
      let res = '';
      try {
        if (record.type === "BAG_PICKUP") {
          res = await dataProvider.getOne('pickups', { id: record.id });
        } else {
          res = await dataProvider.getOne('orders', { id: record.id });
        }
      } catch (error) {
        console.warn('Error: ', error)
      }
      setStatus(status => {
        if (record.type === "BAG_PICKUP") {
          return { status, ...{ id: res?.data?.bagPickupStatus?.bagPickupStatusName }}
        } else {
          return { status, ...{ id: res?.data?.orderStatus?.orderStatusName }}
        }
      })
    })(record)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record])

  return <div className={classNames(styles.statusText, styles[status && status.id && status.id.toLowerCase()])}>{status.id}</div>;
}

export const DeliveriesList = props => {
  const currentDay = String(new Date().getDate()).padStart(2, '0');;
  const currentMonth = String(new Date().getMonth() + 1).padStart(2, '0');
  const currenntYear = new Date().getFullYear();
  const startDate = currenntYear + "-" + currentMonth + "-" + currentDay + "T00:01:00.000Z";
  const endDate = currenntYear + "-" + currentMonth + "-" + currentDay + "T20:59:00.000Z";

  return (
    <List
      pagination={<AdminPagination />}
      perPage={25}
      filterDefaultValues={{ startDate, endDate }}
      filters={<DeliveriesFilter />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      {...props}
    >
      <Datagrid bulkActionButtons={false}>
        <FunctionField render={(record) => <span>{record?.id && record.type === 'BAG_PICKUP' ? record.id : 'N/A'}</span>} label="Pickup ID" />
        <GetStatus label="Status" />
        <FunctionField render={(record) => <span>{record.firstName} {record.lastName}</span>} label="Full Name" />
        <TextField source="phone" label="Phone" />
        <FunctionField render={(record) => {
          return (
            <div label="Actions">
              <EditButton
                label="Edit"
                component={Link}
                to={{ pathname: `/${record.type === "BAG_SHIPMENT" ? 'orders' : 'pickups'}/${record.id}` }}
                variant="contained"
              />
            </div>)
        }}/>
        <FunctionField render={record => <span><b>{formatDateMinus2Hours(record && record.startDate)}</b> ({record.timeId})</span>} label="Delivery slot (Van Slot ID)" />
        <FunctionField render={
          record => {
            const fullAddress = record && record.street + " " + record.streetNumber + ", " +  record.city + ", " + record.pc;
            return (
              <Fragment>
                <GetGoogleMapsLink address={fullAddress}/>
                {fullAddress}
              </Fragment>
            );
          }}
          label="Address"
        />
        <FunctionField render={record => <span>{record && record.type}</span>} label="Type" />
        <ChipField source="bags" label="No of Bags" />
      </Datagrid>
    </List>
  )
};

const Deliveries = () => {
  return (
    <Resource
      name="deliveries"
      icon={AccessTimeIcon}
      options={{ label: 'Bag Deliveries' }}
      list={DeliveriesList}
    />
  );
}

export default Deliveries;
