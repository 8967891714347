import React from 'react';
import { Title } from 'react-admin';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Widget from 'src/common/widget/widget';
import ManWomanKidWidget from 'src/Dashboard/widgets/products/manWomanKid';

import {
  MAN_WOMAN_KID_ACCESSORIES,
  MAN_WOMAN_KID_ALL,
  MAN_WOMAN_KID_CLOTHES,
  MAN_WOMAN_KID_ALL_SPRING_SUMMER,
  MAN_WOMAN_KID_ALL_AUTUMN_WINTER,
  MAN_WOMAN_KID_ALL_ALL_YEAR,
} from 'src/Dashboard/widgets/products/queries';

import styles from 'src/Dashboard/styles.module.scss';

const AllProducts = () => {
  return (
    <Card>
      <Title title="Welcome to Cirkel Admin" />
      <CardContent>
        <span className={styles.widgetsTitle}>Products Key Metrics</span>
        <div className={styles.widgetsRow}>
          <Widget title="All Products" subtitle={"Under Sell"}>
            {ManWomanKidWidget(MAN_WOMAN_KID_ALL)}
          </Widget>
          <Widget title="Clothes" subtitle={"Under Sell"}>
            {ManWomanKidWidget(MAN_WOMAN_KID_CLOTHES)}
          </Widget>
          <Widget title="Accessories" subtitle={"Under Sell"}>
            {ManWomanKidWidget(MAN_WOMAN_KID_ACCESSORIES)}
          </Widget>
        </div>
        <div className={styles.widgetsRow}>
          <Widget title="Spring/Summer" subtitle={"All items - Under Sell"}>
            {ManWomanKidWidget(MAN_WOMAN_KID_ALL_SPRING_SUMMER)}
          </Widget>
          <Widget title="Autumn/Winter" subtitle={"All items - Under Sell"}>
            {ManWomanKidWidget(MAN_WOMAN_KID_ALL_AUTUMN_WINTER)}
          </Widget>
          <Widget title="For All Year" subtitle={"All items - Under Sell"}>
            {ManWomanKidWidget(MAN_WOMAN_KID_ALL_ALL_YEAR)}
          </Widget>
        </div>
      </CardContent>
    </Card>
  );
};

export default AllProducts;