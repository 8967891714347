import React from 'react';
import { Resource } from 'react-admin';

import RedeemIcon from '@mui/icons-material/Redeem';

import {
  ProductList,
  ProductEdit,
  ProductCreate
} from './views';

const AllProducts = () => {
  return (
    <Resource
      name="products"
      icon={RedeemIcon}
      options={{ label: 'Products' }}
      list={ProductList}
      edit={ProductEdit}
      create={ProductCreate}
    />
  );
}

export default AllProducts;
