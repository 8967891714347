import React from 'react';
import { Resource } from 'react-admin';

import {
  PaymentMethodsEdit,
  PaymentMethodsList,
  PaymentMethodsCreate
} from './views';

const PaymentMethods = () => {
  return (
    <Resource
      name="payment-methods"
      options={{ label: 'Payment Methods' }}
      list={PaymentMethodsList}
      create={PaymentMethodsCreate}
      edit={PaymentMethodsEdit}
    />
  );
}

export default PaymentMethods;
