import React from 'react';
import { Resource } from 'react-admin';

import UserCreate from './views/create';
import UserEdit from './views/edit';
import UserList from './views/list';

// eslint-disable-next-line no-unused-vars
const Aside = () => (
  <div style={{ width: 200, margin: '1em' }}>
      <div variant="h6">User details</div>
      <div variant="body2">
          Coming soon!
      </div>
  </div>
);

const Users = () => {
  return (
    <Resource
      name="users"
      options={{ label: 'Users' }}
      list={UserList}
      edit={UserEdit}
      create={UserCreate}
    />
  );
}

export default Users;
