import React from 'react';

const ComingSoon = () => {
  const style = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center',
    backgroundColor: '#f0f0f0',
    color: '#333',
    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  };

  const headingStyle = {
    fontSize: '48px',
    margin: '0 0 20px 0',
  };

  const textStyle = {
    fontSize: '24px',
  };

  return (
    <div style={style}>
      <h1 style={headingStyle}>Coming Soon!</h1>
      <p style={textStyle}>We're working hard to bring you something amazing. Stay tuned!</p>
    </div>
  );
}

export default ComingSoon;