import {
  Resource,
  List,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  FunctionField,
  SimpleForm,
  TextInput,
  Create,
  TextField,
  SelectInput,
} from 'react-admin';

import { availableLanguages } from '../../utils';
import { AdminPagination } from '../../common/AdminPagination';

const TranslationPanel = () => {
  return (
    <span>Coming soon!</span>
  );
};

const Disclaimer = () => (
  <div style={{ padding: '1em', backgroundColor: '#f9f9f9', marginBottom: '1em', borderRadius: '4px', borderLeft: '5px solid #ccc', width: '15%', textAlign: 'center' }}>
    <span style={{ fontSize: '5em', color: '#FFCC00', display: 'block' }}>⚠️</span>
    <div>
      <h4>Disclaimer</h4>
      <p>Only make edits on this page if you know what you are doing!</p>
    </div>
  </div>
);

export const TranslationList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    pagination={<AdminPagination />}
    perPage={100}
    filters={<TranslationsFilter />}
    aside={<Disclaimer />}
  >
    <Datagrid expand={<TranslationPanel />}>
      <EditButton label="Edit" variant="contained" />
      <TextField source="key" />
      <FunctionField
        render={(record) => {
          return (
            <div style={{minWidth: "250px"}}>{record.language === "en" ? "🇺🇸 English" : "🇬🇷 Greek" }</div>
          );
        }}
        label="Language"
      />
      <TextField source="value" />
    </Datagrid>
  </List>
);

const TranslationsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Key" source="id.key" alwaysOn resettable />
    <SelectInput label="Language" source="id.language" alwaysOn choices={availableLanguages} resettable />
    <TextInput label="Value" source="value" alwaysOn resettable />
  </Filter>
  );

export const TranslationEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="key" fullWidth resettable />
      <SelectInput label="Language" source="language" choices={availableLanguages} resettable />
      <TextInput source="value" multiline resettable fullWidth/>
    </SimpleForm>
  </Edit>
);

export const TranslationsCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="key" />
      <SelectInput label="Language" source="language" choices={availableLanguages} />
      <TextInput source="value" multiline />
    </SimpleForm>
  </Create>
);

const Translations = () => {
  return (
    <Resource
      name="translations"
      options={{ label: 'Translations' }}
      list={TranslationList}
      edit={TranslationEdit}
      create={TranslationsCreate}
    />
  );
}

export default Translations;
