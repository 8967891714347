export const MAN_WOMAN_KID_ALL = {
  man: {
    pagination: { page: 1, perPage: 1},
    filter: { "productInfo.gender" : "MALE", "productStatusName": "UNDER_SELL",}
  },
  woman: {
    pagination: { page: 1, perPage: 1},
    filter: { "productInfo.gender" : "FEMALE", "productStatusName": "UNDER_SELL"}
  },
  kid: {
    pagination: { page: 1, perPage: 1},
    filter: { "productInfo.gender" : "KID", "productStatusName": "UNDER_SELL"}
  }
}

export const MAN_WOMAN_KID_CLOTHES = {
  man: {
    pagination: { page: 1, perPage: 1},
    filter: { "productInfo.gender" : "MALE", "productStatusName": "UNDER_SELL", "category": 1}
  },
  woman: {
    pagination: { page: 1, perPage: 1},
    filter: { "productInfo.gender" : "FEMALE", "productStatusName": "UNDER_SELL", "category": 1}
  },
  kid: {
    pagination: { page: 1, perPage: 1},
    filter: { "productInfo.gender" : "KID", "productStatusName": "UNDER_SELL", "category": 1}
  }
}

export const MAN_WOMAN_KID_ACCESSORIES = {
  man: {
    pagination: { page: 1, perPage: 1},
    filter: { "productInfo.gender" : "MALE", "productStatusName": "UNDER_SELL", "category": 2}
  },
  woman: {
    pagination: { page: 1, perPage: 1},
    filter: { "productInfo.gender" : "FEMALE", "productStatusName": "UNDER_SELL", "category": 2}
  },
  kid: {
    pagination: { page: 1, perPage: 1},
    filter: { "productInfo.gender" : "KID", "productStatusName": "UNDER_SELL", "category": 2}
  }
}

export const MAN_WOMAN_KID_ALL_SPRING_SUMMER = {
  man: {
    pagination: { page: 1, perPage: 1},
    filter: { "productInfo.gender" : "MALE", "productStatusName": "UNDER_SELL", "productClothesFilters.season": "SPRING_SUMMER"}
  },
  woman: {
    pagination: { page: 1, perPage: 1},
    filter: { "productInfo.gender" : "FEMALE", "productStatusName": "UNDER_SELL", "productClothesFilters.season": "SPRING_SUMMER"}
  },
  kid: {
    pagination: { page: 1, perPage: 1},
    filter: { "productInfo.gender" : "KID", "productStatusName": "UNDER_SELL", "productClothesFilters.season": "SPRING_SUMMER"}
  }
}

export const MAN_WOMAN_KID_ALL_AUTUMN_WINTER = {
  man: {
    pagination: { page: 1, perPage: 1},
    filter: { "productInfo.gender" : "MALE", "productStatusName": "UNDER_SELL", "productClothesFilters.season": "AUTUMN_WINTER"}
  },
  woman: {
    pagination: { page: 1, perPage: 1},
    filter: { "productInfo.gender" : "FEMALE", "productStatusName": "UNDER_SELL", "productClothesFilters.season": "AUTUMN_WINTER"}
  },
  kid: {
    pagination: { page: 1, perPage: 1},
    filter: { "productInfo.gender" : "KID", "productStatusName": "UNDER_SELL", "productClothesFilters.season": "AUTUMN_WINTER"}
  }
}

export const MAN_WOMAN_KID_ALL_ALL_YEAR = {
  man: {
    pagination: { page: 1, perPage: 1},
    filter: { "productInfo.gender" : "MALE", "productStatusName": "UNDER_SELL", "productClothesFilters.season": "ALL_YEAR"}
  },
  woman: {
    pagination: { page: 1, perPage: 1},
    filter: { "productInfo.gender" : "FEMALE", "productStatusName": "UNDER_SELL", "productClothesFilters.season": "ALL_YEAR"}
  },
  kid: {
    pagination: { page: 1, perPage: 1},
    filter: { "productInfo.gender" : "KID", "productStatusName": "UNDER_SELL", "productClothesFilters.season": "ALL_YEAR"}
  }
}

export const SEASONS_ALL = {
  allYear: {
    pagination: { page: 1, perPage: 1},
    filter: { "productStatusName": "UNDER_SELL", "productClothesFilters.season": "ALL_YEAR"}
  },
  autumnWinter: {
    pagination: { page: 1, perPage: 1},
    filter: { "productStatusName": "UNDER_SELL", "productClothesFilters.season": "AUTUMN_WINTER"}
  },
  springSummer: {
    pagination: { page: 1, perPage: 1},
    filter: { "productStatusName": "UNDER_SELL", "productClothesFilters.season": "SPRING_SUMMER"}
  }
}

export const SEASONS_MAN = {
  allYear: {
    pagination: { page: 1, perPage: 1},
    filter: { "productStatusName": "UNDER_SELL", "productClothesFilters.season": "ALL_YEAR", "productInfo.gender" : "MALE" }
  },
  autumnWinter: {
    pagination: { page: 1, perPage: 1},
    filter: { "productStatusName": "UNDER_SELL", "productClothesFilters.season": "AUTUMN_WINTER", "productInfo.gender" : "MALE" }
  },
  springSummer: {
    pagination: { page: 1, perPage: 1},
    filter: { "productStatusName": "UNDER_SELL", "productClothesFilters.season": "SPRING_SUMMER", "productInfo.gender" : "MALE" }
  }
}

export const SEASONS_WOMAN = {
  allYear: {
    pagination: { page: 1, perPage: 1},
    filter: { "productStatusName": "UNDER_SELL", "productClothesFilters.season": "ALL_YEAR", "productInfo.gender" : "FEMALE"}
  },
  autumnWinter: {
    pagination: { page: 1, perPage: 1},
    filter: { "productStatusName": "UNDER_SELL", "productClothesFilters.season": "AUTUMN_WINTER", "productInfo.gender" : "FEMALE"}
  },
  springSummer: {
    pagination: { page: 1, perPage: 1},
    filter: { "productStatusName": "UNDER_SELL", "productClothesFilters.season": "SPRING_SUMMER", "productInfo.gender" : "FEMALE"}
  }
}

export const SEASONS_KID = {
  allYear: {
    pagination: { page: 1, perPage: 1},
    filter: { "productStatusName": "UNDER_SELL", "productClothesFilters.season": "ALL_YEAR", "productInfo.gender" : "KID"}
  },
  autumnWinter: {
    pagination: { page: 1, perPage: 1},
    filter: { "productStatusName": "UNDER_SELL", "productClothesFilters.season": "AUTUMN_WINTER", "productInfo.gender" : "KID"}
  },
  springSummer: {
    pagination: { page: 1, perPage: 1},
    filter: { "productStatusName": "UNDER_SELL", "productClothesFilters.season": "SPRING_SUMMER", "productInfo.gender" : "KID"}
  }
}