import { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import { BarChart } from '@mui/x-charts/BarChart';
import { getHeaders } from 'src/utils';

const MonthlySalesWidget = () => {
  const dataProvider = useDataProvider();
  const [monthlySalesData, setMonthlySalesData] = useState([]);

  const baseUrl = "https://server.cirkel.gr/admin/products?page=1&size=1";

  useEffect(() => {
    const fetchMonthlySalesData = async (year) => {
      let monthlyData = [];
      for (let month = 1; month <= 12; month++) {
        const startDate = new Date(year, month - 1, 1).toISOString();
        const endDate = new Date(year, month, 0, 23, 59, 59).toISOString();
        const param = `&soldAt=${startDate}&soldAt=${endDate}`;

        try {
          const response = await fetch(baseUrl + param, { method: 'GET', headers: getHeaders() })
            .then(res => res.json());
          monthlyData.push(response?.total || 0);
        } catch (error) {
          console.warn('Error fetching monthly sales data:', error);
          monthlyData.push(0); // Assume 0 sales for error cases
        }
      }
      return monthlyData;
    };

    const getAllMonthlySalesData = async () => {
      const salesData2022 = await fetchMonthlySalesData(2022);
      const salesData2023 = await fetchMonthlySalesData(2023);
      const salesData2024 = await fetchMonthlySalesData(2024);
      setMonthlySalesData([...salesData2022, ...salesData2023, ...salesData2024]);
    };

    getAllMonthlySalesData();
  }, [dataProvider]); // Dependency array to avoid infinite loop

  const xAxisData = Array.from({ length: 36 }, (_, i) => `${i < 12 ? '2022' : i < 24 ? '2023' : '2024'}-${((i % 12) + 1).toString().padStart(2, '0')}`);

  return (
    <BarChart
      xAxis={[{ scaleType: 'band', data: xAxisData }]}
      series={[{ data: monthlySalesData }]}
      width={1000}
      height={300}
    />
  );
};

export default MonthlySalesWidget;