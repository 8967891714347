import React from 'react';
import {
  Resource,
  List,
  Datagrid,
  TextField,
  Edit,
  Create,
  Show,
  Filter,
  SimpleForm,
  ImageInput,
  ChipField,
  RichTextField,
  ImageField,
  TextInput,
  SimpleShowLayout,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  FunctionField
} from 'react-admin';

import { RichTextInput } from 'ra-input-rich-text';
import { BackgroundImageField } from '../../utils';
import { AdminPagination } from '../../common/AdminPagination';

const Slug = ({ record }) => {
  return <div><code>{record.slug}</code></div>;
}

export const SubCategoryList = props => (
  <List pagination={<AdminPagination />} perPage={25} {...props} bulkActionButtons={false} filters={<CategoriesFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="Subcategory ID" />
      <TextField source="uuid" label="UUID" />
      <BackgroundImageField firstOf uuid label="Featured Image" resource="subcategories" className="as-image rounded small" />
      <TextField source="subcategoryName" label="Title" />
      <FunctionField render={record => record && <Slug record={record} />} label="Slug" />
      <ReferenceField source="categoryId" reference="categories"><ChipField source="categoryName" /></ReferenceField>
      <RichTextField source="subcategoryDescription" label="Description" />
    </Datagrid>
  </List>
);

const CategoriesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Title" source="subcategoryName" alwaysOn />
    <TextInput label="ID" source="id" alwaysOn />
    <TextInput label="Description" source="subcategoryDescription" />
    <ReferenceInput
      label="Category"
      source="categoryId"
      reference="categories"
      sort={{ field: 'categoryName', order: 'ASC' }}
      filterToQuery={searchText => ({ categoryName: searchText })}
      allowEmpty={true}
      alwaysOn
    >
      <AutocompleteInput optionText="categoryName" autoComplete={false} />
    </ReferenceInput>
  </Filter>
  );

export const SubCategoryShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ImageField source="image" title="Featured Image" />
      <TextField source="subcategoryName" />
      <TextInput multiline source="subcategoryDescription" />
    </SimpleShowLayout>
  </Show>
);

export const SubCategoryEdit = props => (
  <Edit {...props}>
    <SimpleForm redirect={false}>
      <TextField source="id" />
      <TextInput source="subcategoryName" />
      <TextInput source="slug" />
      <div className="helper-text">* No capital letters, special characters and spaces</div>
      <ReferenceInput
        label="Category"
        source="categoryId"
        reference="categories"
        sort={{ field: 'categoryName', order: 'ASC' }}
        filterToQuery={searchText => ({ categoryName: searchText })}
        allowEmpty={true}
        alwaysOn
      >
        <AutocompleteInput optionText="categoryName" autoComplete={false} />
      </ReferenceInput>
      <ImageField source="image" title="Featured image"/>
      <ImageInput className="fullWidth" source="images" accept="image/jpeg" maxSize={500000}>
        <ImageField source="data" />
      </ImageInput>
      <div className="helper-text">* The image must be named 1.jpeg</div>
      <div className="helper-text">** Max size 400kb</div>
      <TextInput multiline source="subcategoryDescription" />
    </SimpleForm>
  </Edit>
);

export const SubCategoryCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="subcategoryName" />
      <TextInput source="slug" />
      <div className="helper-text">* No capital letters, special characters and spaces</div>
      <ReferenceInput
        label="Category"
        source="categoryId"
        reference="categories"
        sort={{ field: 'categoryName', order: 'ASC' }}
        filterToQuery={searchText => ({ categoryName: searchText })}
        allowEmpty={true}
        alwaysOn
      >
        <AutocompleteInput optionText="categoryName" autoComplete={false} />
      </ReferenceInput>
      <RichTextInput multiline source="subcategoryDescription" />
    </SimpleForm>
  </Create>
);

const Subcategories = () => {
  return (
    <Resource name="subcategories"
      options={{ label: 'Subcategories' }}
      list={SubCategoryList}
      edit={SubCategoryEdit}
      create={SubCategoryCreate}
      show={SubCategoryShow}
    />
  );
}

export default Subcategories;
