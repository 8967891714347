import React from 'react';
import { Title } from 'react-admin';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Widget from 'src/common/widget/widget';
import PickupCountWidget from 'src/Dashboard/widgets/pickups/pickups';
import TodaysVanSlotsWidget from 'src/Dashboard/widgets/vanSlots/vanSlots';
import PhotographingWidget from 'src/Dashboard/widgets/status/photographingProducts';
import ReservedWidget from 'src/Dashboard/widgets/status/reservedProducts';

import styles from 'src/Dashboard/styles.module.scss';
import CreatedProductsWidget from 'src/Dashboard/widgets/status/createdProducts';

const Dashboard = () => {
  return (
    <Card>
      <Title title="Welcome to Cirkel Admin" />
      <CardContent>
        <span className={styles.widgetsTitle}>Operations Key Metrics</span>
        <div className={styles.widgetsRow}>
          <Widget title="🛍️ Pickups in Storage">
            {PickupCountWidget()}
          </Widget>
          <Widget title="🚐 Van Slots Today:">
            {TodaysVanSlotsWidget()}
          </Widget>
          <Widget title="📷 Photographing:">
            {PhotographingWidget()}
          </Widget>
          <Widget title="🟠 Reserved:" subtitle={"Should be close to 0!"}>
            {ReservedWidget()}
          </Widget>
          <Widget title="🔵 Created:" subtitle={"Should be 0!"}>
            {CreatedProductsWidget()}
          </Widget>
        </div>
      </CardContent>
    </Card>
  );
};

export default Dashboard;