
import { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';

import styles from './styles.module.scss';

const CreatedProductsWidget = () => {

  const dataProvider = useDataProvider();
  const [photographingCount, setPhotographingCount] = useState(0);

  useEffect(() => {
    const GetVanSlots = async () => {
      try {
        const res = await dataProvider.getList("products",
          {
            filter: { "productStatusName": "CREATED" },
            pagination: { page: 1, perPage: 1 },
          },
        );
        setPhotographingCount(res?.total || res?.data?.length || 0);
      } catch (error) {
        console.warn('Error: ', error);
      }
    };

    GetVanSlots();
  }, [dataProvider]); // Dependency array to avoid infinite loop

  return (
    <div className={styles.countText}>
      {photographingCount}
    </div>
  );
};

export default CreatedProductsWidget;
