
import { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import { getHeaders } from 'src/utils';
import { Title } from 'react-admin';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import styles from './index.module.scss';

import Widget from 'src/common/widget/widget';

const StorageOverview = () => {

  const dataProvider = useDataProvider();

  const baseUrl = "https://server.cirkel.gr/admin/products?page=1&size=1&productStatusName=UNDER_SELL&productFilters.inStoreDisplay=false";

  const [storageRaData, setStorageRaData] = useState([]);
  const [storageStbData, setStorageStbData] = useState([]);
  const [storageStcData, setStorageStcData] = useState([]);

  // 01/STA/RA XX:
  const createRARequests = () => {
    const raRequests = [];
    for (let i = 1; i <= 38; i++) {
      raRequests.push(`&storagePosition=01/STA/RA${i.toString()}`);
    }
    return raRequests;
  };
  const raRequests = createRARequests();

  // 01/STB/BO XX:
  const createSTBRequests = () => {
    const stbRequests = [];
    for (let i = 1; i <= 18; i++) {
      stbRequests.push(`&storagePosition=01/STB/BO${i.toString()}`);
    }
    return stbRequests;
  };
  const stbRequests = createSTBRequests();

  // 01/STC/BO XX:
  const createSTCRequests = () => {
    const stcRequests = [];
    for (let i = 1; i <= 29; i++) {
      stcRequests.push(`&storagePosition=01/STC/BO${i.toString()}`);
    }
    return stcRequests;
  };

  const stcRequests = createSTCRequests();

  useEffect(() => {
    const GetStorageRaData = async () => {
      try {
        const storageRaData = [];
        for (let i = 0; i < raRequests.length; i++) {
          const response = await fetch (baseUrl + raRequests[i],
            { method: 'GET', headers: getHeaders() })
            .then(res => res.json());
            storageRaData.push(response?.total || 0);
        }
        setStorageRaData(storageRaData);
      } catch (error) {
        console.warn('Error: ', error);
      }
    };

    const GetStorageStbData = async () => {
      try {
        const storageStbData = [];
        for (let i = 0; i < stbRequests.length; i++) {
          const response = await fetch (baseUrl + stbRequests[i],
            { method: 'GET', headers: getHeaders() })
            .then(res => res.json());
            storageStbData.push(response?.total || 0);
        }
        setStorageStbData(storageStbData);
      } catch (error) {
        console.warn('Error: ', error);
      }
    }

    const GetStorageStcData = async () => {
      try {
        const storageStcData = [];
        for (let i = 0; i < stcRequests.length; i++) {
          const response = await fetch (baseUrl + stcRequests[i],
            { method: 'GET', headers: getHeaders() })
            .then(res => res.json());
            storageStcData.push(response?.total || 0);
        }
        setStorageStcData(storageStcData);
      } catch (error) {
        console.warn('Error: ', error);
      }
    }

    GetStorageRaData();
    GetStorageStbData();
    GetStorageStcData();
  }, [dataProvider]);

  return (
    <Card>
    <Title title="Storage Overview" />
    <CardContent>
      <span className={styles.widgetsTitle}>Storage A</span>
      <div className={styles.title}></div>
      <div className={styles.widgetsRow}>
        {storageRaData.map((number, index) => (
          <Widget title={"01/STA/RA" + (index+1)} key={"STA"+index}>
            <Gauge
              width={200}
              height={120}
              value={number > 100 ? 100 : number}
              startAngle={-100}
              endAngle={100}
              sx={{
                [`& .${gaugeClasses.valueText}`]: {
                  fontSize: 24,
                  transform: 'translate(0px, 0px)',
                },
                [`& .${gaugeClasses.valueArc}`]: {
                  fill: number > 80 ? "red" : number > 60 ? "orange" : number > 20 ? "green" : "blue",
                },
              }}
              text={`${number} / ${100}`}
            />
          </Widget>
        ))}
        </div>
        <span className={styles.widgetsTitle}>Storage B</span>
        <div className={styles.widgetsRow}>
          {storageStbData.map((number, index) => {
            const value = number * 2;
            return (
              <Widget title={"01/STB/BO" + (index+1)} key={"STB"+index}>
                <Gauge
                  width={200}
                  height={120}

                  value={value > 100 ? 100 : value}
                  startAngle={-50}
                  endAngle={50}
                  sx={{
                    [`& .${gaugeClasses.valueText}`]: {
                      fontSize: 24,
                      transform: 'translate(0px, 0px)',
                    },
                    [`& .${gaugeClasses.valueArc}`]: {
                      fill: number > 80 ? "red" : number > 60 ? "orange" : number > 20 ? "green" : "blue",
                    },
                  }}
                  text={`${number} / ${50}`}
                />
              </Widget>
          )})}
        </div>
        <span className={styles.widgetsTitle}>Storage C</span>
        <div className={styles.widgetsRow}>
          {storageStcData.map((number, index) => {
            const value = number * 2;
            return (
              <Widget title={"01/STC/BO" + (index+1)} key={"STC"+index}>
                <Gauge
                  width={200}
                  height={120}
                  value={value > 100 ? 100 : value}
                  startAngle={-50}
                  endAngle={50}
                  sx={{
                    [`& .${gaugeClasses.valueText}`]: {
                      fontSize: 24,
                      transform: 'translate(0px, 0px)',
                    },
                    [`& .${gaugeClasses.valueArc}`]: {
                      fill: number > 80 ? "red" : number > 60 ? "orange" : number > 20 ? "green" : "blue",
                    },
                  }}
                  text={`${number} / ${50}`}
                />
              </Widget>
              )
          })}
        </div>
      </CardContent>
    </Card>
  );
}

export default StorageOverview;
