import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
  ArrayField,
  Button,
  ChipField,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Filter,
  FormTab,
  FunctionField,
  Labeled,
  List,
  NumberField,
  NumberInput,
  Resource,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  useCreatePath,
  useDataProvider,
  useRecordContext
} from 'react-admin';
import classNames from 'classnames';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button as MUIButton } from '@mui/material';

import {
  availableOrderStatus,
  AddressSnapshot,
} from '../../utils';
import { AdminPagination } from '../../common/AdminPagination';
import { BackgroundImageField } from '../../utils';

import styles from './styles.module.scss';

const GetUserFullname = (props) => {
  const record = useRecordContext(props);
  const dataProvider = useDataProvider();
  const [userFullname, setFullName] = useState({});

  useEffect(() => {
    (async (record) => {
      let res = '';
      try {
        res = await dataProvider.getOne('users', { id: record?.userId });
      } catch (error) {
        console.warn('Error: ', error)
      }
      setFullName(status => {
        return { status, ...{ name: res?.data?.firstName + ' ' + res?.data?.lastName}}
      })
    })(record)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record])

  return <span>{userFullname.name}</span>
}

const ProductInStore = (props) => {
  const record = useRecordContext(props);
  const dataProvider = useDataProvider();
  const [productInStore, setProductInStore] = useState(false);

  useEffect(() => {
    (async (record) => {
      let response = '';
      try {
        response = await dataProvider.getOne('products', { id: record?.id });
      } catch (error) {
        console.warn('Error: ', error)
      }
      setProductInStore(() => {
        return response?.data?.inStoreDisplay || false;
      })
    })(record)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record])

  return <span>{productInStore ? "✅" : "❌"}</span>
}

const ProductStoragePosition = (props) => {
  const record = useRecordContext(props);
  const dataProvider = useDataProvider();
  const [storagePosition, setStoragePosition] = useState(false);

  useEffect(() => {
    (async (record) => {
      let response = '';
      try {
        response = await dataProvider.getOne('products', { id: record?.id });
      } catch (error) {
        console.warn('Error: ', error)
      }
      setStoragePosition(() => {
        return response?.data?.storagePosition || false;
      })
    })(record)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record])

  return <span>{storagePosition || "❌"}</span>
}

const GetUserPhone = (props) => {
  const record = useRecordContext(props);
  const dataProvider = useDataProvider();
  const [userPhone, setUserPhone] = useState("loading...");

  useEffect(() => {
    (async (record) => {
      let res = '';
      try {
        res = await dataProvider.getOne('users', { id: record?.userId });
      } catch (error) {
        console.warn('Error: ', error)
      }
      setUserPhone(status => {
        return res?.data?.phoneNumber;
      })
    })(record)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record])

  return (
    <FunctionField render={
      () => {
        return (
          <Fragment>
            <Button
              style={{ minWidth: '32px' }}
              sx={{ "& .MuiButton-startIcon": { marginRight: "0px" }}}
              onClick={() => navigator.clipboard.writeText(userPhone)}
            >
              <ContentCopyIcon fontSize='small' />
            </Button>
            <span>{userPhone}</span>
          </Fragment>
        )
      }
    }
      label="Name"
    />
  );
}

const CopyOrderCode = () => {
  const record = useRecordContext();

  return (
    <Button
      style={{ minWidth: '32px' }}
      sx={{ "& .MuiButton-startIcon": { marginRight: "0px" }}}
      onClick={() => navigator.clipboard.writeText(record?.code)}
    >
      <ContentCopyIcon fontSize='small' />
    </Button>
  );
}

const ShipmentMethod = () => {
  const record = useRecordContext();

  if (record.shipmentTypeSnapshot) {
    if (record.shipmentTypeSnapshot.name === "STORE") {
      // User chose to pickup from store:
      return (<div className={classNames(styles.deliveryMethod, styles.productStorePickup)}>🎁 Store Pickup</div>);
    } else {
      if (record.shipmentTypeSnapshot.name === "BOXNOW") {
        return (<div className={classNames(styles.deliveryMethod, styles.boxnow)}>📦 {record.shipmentTypeSnapshot.name}</div>);
      } else {
        // Show the provider the user chose:
        return (<div className={classNames(styles.deliveryMethod, styles.shippingProvider)}>📦 {record.shipmentTypeSnapshot.name}</div>);
      }
    }
  } else {
    if (record?.shipment?.bagShipmentDto?.shipmentTime) {
      const vanslotId = record?.shipment?.bagShipmentDto?.shipmentTime.id;
      if (vanslotId === 1) {
        return (<div className={classNames(styles.deliveryMethod, styles.bagStorePickup)}>🛍️ Bag Store Pickup</div>);
      } else {
        return (<div className={classNames(styles.deliveryMethod, styles.vanSlot)}>🚐 Van Slot: {vanslotId}</div>);
      }
    }
    return (
      <div className={classNames(styles.deliveryMethod, styles.boughtInStore)}>Bought in Store</div>
    );
  }
}

const PaymentMethod = () => {
  const record = useRecordContext();

  if (record.paymentMethodSnapshot) {
    if (record.paymentMethodSnapshot.imageName === "payAtDoor")
      return "Pay At Door";
    else if (record.paymentMethodSnapshot.imageName === "creditCard")
      return "Stripe";
  } else {
      return "Stripe";
    }
}

export const OrderList = props => (
  <List
    {...props}
    filters={<OrdersFilter />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    pagination={<AdminPagination />}
    perPage={25}
  >
    <Datagrid rowClick={false}>
      <FunctionField render={
        () => {
          return (
            <Fragment>
              <CopyOrderCode />
              <TextField source="code" label="Cirkel Order Code" />
            </Fragment>
          )
        }
      }
        label="Name"
      />
      <ChipField source="orderStatus.orderStatusName" label="Status" />
      <EditButton label="Edit" variant="contained" />
      <GetUserFullname label="Full name"/>
      <GetUserPhone label="Phone Number"/>
      <FunctionField render={record => <span>{record?.orderType?.orderTypeName}</span>} label="Type" />
      <ShipmentMethod label="Shipment Method" />
      <PaymentMethod label="Payment Method" />
      <FunctionField render={
        () => {
          return (
            <Fragment>
              💰&nbsp;
              <NumberField
                source="totalPrice"
                locales="el-GR"
                options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }}/>
            </Fragment>
          )
        }
      }
        label="Price"
      />

      <DateField source="createdAt" showTime />
    </Datagrid>
  </List>
);

const OrdersFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Id" source="id" alwaysOn />
    <SelectInput label="Type" source="orderTypeName" resettable alwaysOn choices={[
      { id: 'BAG', name: '🔵 Bag' },
      { id: 'PRODUCT', name: '🟢 Product' }
    ]} />
    <SelectInput label="Status" source="orderStatus.orderStatusName" resettable alwaysOn choices={[
      { id: 'PAID', name: '🔵 Paid' },
      { id: 'PENDING_PAYMENT', name: '🟡 Pending Payment' },
      { id: 'REJECTED', name: '🔴 Rejected' },
      { id: 'SHIPPED', name: '🟢 Shipped' }
    ]} />
    <NumberInput label="Price" source="totalPrice" alwaysOn />
    <TextInput label="Phone number" source="userAddressSnapshot.phoneNumber" alwaysOn />
  </Filter>
);

const GoToUser = () => {
  const record = useRecordContext();
  const createPath = useCreatePath();

  if (!record) {
    return null;
  }
  const userPath = createPath({ resource: 'users', type: 'edit', id: record.userId });

  return (
    <Fragment>
      <Labeled label="User:">
        <GetUserFullname />
      </Labeled>
      <MUIButton
        component={Link}
        variant="contained"
        to={userPath}
      >
        View User
      </MUIButton>
    </Fragment>
  );
};

const EditProduct = () => {
  const record = useRecordContext();
  const createPath = useCreatePath();
  if (!record) {
    return null;
  }
  const productPath = createPath({ resource: 'products', type: 'edit', id: record.id });

  return (
    <Button
      component={Link}
      to={productPath}
      label="Edit"
      variant="contained"
    />
  );
};

const ShipmentSnapshot = () => {
  const record = useRecordContext();

  return (
    <table>
      <thead>
        <tr>
          <th>Company</th>
          <th>Shipping Price</th>
          <th>Price for extra kg</th>
        </tr>
      </thead>
      {record && record.shipmentTypeSnapshot && (
      <tbody>
        <tr>
          <td>{record.shipmentTypeSnapshot.name}</td>
          <td>{record.shipmentTypeSnapshot.priceStandard}€</td>
          <td>{record.shipmentTypeSnapshot.priceExtra}€</td>
        </tr>
      </tbody>
      )}
    </table>
  );
};

const PaymentMethodSnapshot = () => {
  const record = useRecordContext();

  return (
    <table>
      <thead>
        <tr>
          <th>Payment Method</th>
          <th>Price</th>
        </tr>
      </thead>
      {record?.paymentMethodSnapshot && (
      <tbody>
        <tr>
          <td>{record.paymentMethodSnapshot.imageName}</td>
          <td>{record.paymentMethodSnapshot.price}€</td>
        </tr>
      </tbody>
      )}
    </table>
  );
};

const TotalPrice = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }
  return record.totalPrice + ' €';
};

export const OrderEdit = props => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label="Order Details">
        <Labeled label="Cirkel public order code:">
          <TextField source="code"/>
        </Labeled>
        <Labeled label="Order Type:">
          <FunctionField render={(record) => <span>{record?.orderType?.orderTypeName}</span>} />
        </Labeled>
        <Labeled label="Payment Status:">
          <SelectInput label="Status" source="orderStatus.orderStatusName" choices={availableOrderStatus} />
        </Labeled>
        <Labeled label="Total Amount:">
          <TotalPrice />
        </Labeled>
        <GoToUser />
        <Labeled label="Address Details:">
          <AddressSnapshot />
        </Labeled>
        <Labeled label="Shipping Method Details:">
          <ShipmentSnapshot />
        </Labeled>
        <Labeled label="Payment Method Details:">
          <PaymentMethodSnapshot />
        </Labeled>
        <ArrayField source="products">
          <Datagrid rowClick={false}>
            <BackgroundImageField firstOf label="Image" resource="products" />
            <TextField source="code" label="Code" />
            <TextField source="name" label="Product name" />
            <ProductInStore label="In store" />
            <ProductStoragePosition label="Storage Position" />
            <FunctionField render={(record) => record?.price + ' €'} label="Price" />
            <FunctionField render={(record) => record?.salePrice + ' €'} label="Sale Price" />
            <EditProduct label="Action" />
          </Datagrid>
        </ArrayField>
        <FunctionField render={(record) => record?.orderType?.orderTypeName === 'BAG' && (
          <Fragment>
            <span>CIRKEL BAG (x{record?.shipment?.bagShipmentDto?.bags})</span>
            <span>{` `}{record?.totalPrice} euro</span>
          </Fragment>
        )} label="BAGS" />
      </FormTab>
      <FormTab label="Admin">
        <Labeled label="Cirkel internal order ID:">
          <TextField source="id"  />
        </Labeled>
        <Labeled label="Stripe order ID:">
          <TextField source="sessionId" />
        </Labeled>
        <Labeled label="Time Created:">
          <DateField source="createdAt" showTime />
        </Labeled>
        <Labeled label="Updated at:">
          <DateField source="updatedAt" showTime />
        </Labeled>
      </FormTab>
    </TabbedForm>
  </Edit>
);

const Orders = () => {
  return (
    <Resource
      name="orders"
      options={{ label: 'Orders' }}
      list={OrderList}
      edit={OrderEdit}
    />
  );
}

export default Orders;
