import {
  Create,
  SimpleForm,
  SelectArrayInput,
  TextInput,
  BooleanField,
  SelectInput,
} from 'react-admin';

import { userRoles, countryCodes } from '../../../utils';

const UserCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="id" />
      <TextInput source="email" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <SelectInput label="Country code" source="countryCode" choices={countryCodes} />
      <TextInput source="phoneNumber" />
      <TextInput source="profileImage" />
      <BooleanField source="emailVerified" />
      <TextInput source="iban" />
      <TextInput source="vatRegistrationNumber" />
      <SelectArrayInput label="Roles" source="roles" choices={userRoles} />
    </SimpleForm>
  </Create>
);

export default UserCreate;
