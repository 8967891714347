const conditionOptions = [
  { name: '⭐⭐⭐⭐⭐ Brand New', id: 'NEW' },
  { name: '⭐⭐⭐⭐ Like new', id: 'LIKE_NEW' },
  { name: '⭐⭐⭐ Very Good', id: 'GOOD' },
  { name: '⭐⭐ Good', id: 'FAIR' },
  { name: '⭐ Poor', id: 'POOR' }
];

const coverOptions = [
  { name: 'Hardcover', id: 'HARDCOVER' },
  { name: 'Softcover', id: 'SOFTCOVER' }
];

const languageOptions = [
  { name: '🇺🇸 English', id: 'ENGLISH' },
  { name: '🇫🇷 French', id: 'FRENCH' },
  { name: '🇩🇪 German', id: 'GERMAN' },
  { name: '🇬🇷 Greek', id: 'GREEK' },
  { name: '🇮🇹 Italian', id: 'ITALIAN' },
  { name: '🇪🇸 Spanish', id: 'SPANISH' },
  { name: '🇸🇪 Swedish', id: 'SWEDISH' },
  { name: '🇹🇷 Turkish', id: 'TURKISH' }
];

const accessorySizeOptions = [
  { name: '3XS', id: 'XXXS' },
  { name: '2XS', id: 'XXS' },
  { name: 'XS', id: 'XS' },
  { name: 'XS/S', id: 'XS_S' },
  { name: 'S', id: 'S' },
  { name: 'S/M', id: 'S_M' },
  { name: 'M', id: 'M' },
  { name: 'M/L', id: 'M_L' },
  { name: 'L', id: 'L' },
  { name: 'L/XL', id: 'L_XL' },
  { name: 'XL', id: 'XL' },
  { name: '2XL', id: 'XXL' },
  { name: '3XL', id: 'XXXL' },
  { name: '4XL', id: 'XXXXL' },
  { name: 'One Size', id: 'ONE_SIZE' },
  { name: '0-3 months old', id: 'M0_3' },
  { name: '3-6 months old', id: 'M3_6' },
  { name: '6-9 months old', id: 'M6_9' },
  { name: '9-12 months old', id: 'M9_12' },
  { name: '1 year old', id: 'Y1' },
  { name: '2 years old', id: 'Y2' },
  { name: '3 years old', id: 'Y3' },
  { name: '4 years old', id: 'Y4' },
  { name: '5 years old', id: 'Y5' },
  { name: '6 years old', id: 'Y6' },
  { name: '7 years old', id: 'Y7' },
  { name: '8 years old', id: 'Y8' },
  { name: '9 years old', id: 'Y9' },
  { name: '10 years old', id: 'Y10' },
  { name: '11 years old', id: 'Y11' },
  { name: '12 years old', id: 'Y12' },
  { name: '13 years old', id: 'Y13' },
  { name: '14 years old', id: 'Y14' },
  { name: '15 years old', id: 'Y15' },
  { name: '16 years old', id: 'Y16' }
];

const accessoryColorOptions = [
  { name: '🏼 Beige', id: 'BEIGE' },
  { name: '⚫ Black', id: 'BLACK' },
  { name: '🔵 Blue', id: 'BLUE' },
  { name: '🥉 Bronze', id: 'BRONZE' },
  { name: '🤎 Brown', id: 'BROWN' },
  { name: 'Coral', id: 'CORAL' },
  { name: '🔶 Gold', id: 'GOLD' },
  { name: '🟢 Green', id: 'GREEN' },
  { name: '🤍 Grey', id: 'GREY' },
  { name: '🔮 Magenda', id: 'MAGENDA' },
  { name: '🌈 Mixed colours', id: 'MIXED_COLOURS' },
  { name: '🟠 Orange', id: 'ORANGE' },
  { name: '🎀 Pink', id: 'PINK' },
  { name: '🟣 Purple', id: 'PURPLE' },
  { name: '🔴 Red', id: 'RED' },
  { name: '🤍 Silver', id: 'SILVER' },
  { name: '🌫 Transparent', id: 'TRANSPARENT' },
  { name: '⚪ White', id: 'WHITE' },
  { name: '🟡 Yellow', id: 'YELLOW' }
];

const accessoryOccasionOptions = [
  { name: 'BEACH', id: 'BEACH' },
  { name: 'CASUAL', id: 'CASUAL' },
  { name: 'COSY', id: 'COSY' },
  { name: 'DATE', id: 'DATE' },
  { name: 'EVENING', id: 'EVENING' },
  { name: 'FESTIVAL', id: 'FESTIVAL' },
  { name: 'FORMAL', id: 'FORMAL' },
  { name: 'FUN', id: 'FUN' },
  { name: 'LAZY', id: 'LAZY' },
  { name: 'OUTDOORS', id: 'OUTDOORS' },
  { name: 'PARTY', id: 'PARTY' },
  { name: 'RAIN', id: 'RAIN' },
  { name: 'SCHOOL', id: 'SCHOOL' },
  { name: 'SPORTS', id: 'SPORTS' },
  { name: 'UNIVERSITY', id: 'UNIVERSITY' },
  { name: 'WORK', id: 'WORK' }
];

const accessoryMaterialOptions = [
  { name: 'ACETATE', id: 'ACETATE' },
  { name: 'ACRYLIC', id: 'ACRYLIC' },
  { name: 'COTTON', id: 'COTTON' },
  { name: 'DENIM', id: 'DENIM' },
  { name: 'ELASTANE', id: 'ELASTANE' },
  { name: 'FAUX_LEATHER', id: 'FAUX_LEATHER' },
  { name: 'GLASS', id: 'GLASS' },
  { name: 'JERSEY', id: 'JERSEY' },
  { name: 'LINEN', id: 'LINEN' },
  { name: 'LYCRA', id: 'LYCRA' },
  { name: 'METAL', id: 'METAL' },
  { name: 'MOHAIR', id: 'MOHAIR' },
  { name: 'NYLON', id: 'NYLON' },
  { name: 'POLYESTER', id: 'POLYESTER' },
  { name: 'RAYON', id: 'RAYON' },
  { name: 'REAL_LEATHER', id: 'REAL_LEATHER' },
  { name: 'SATIN', id: 'SATIN' },
  { name: 'SILK', id: 'SILK' },
  { name: 'TWEED', id: 'TWEED' },
  { name: 'VELVET', id: 'VELVET' },
  { name: 'VISCOSE', id: 'VISCOSE' },
  { name: 'WOOD', id: 'WOOD' },
  { name: 'WOOL', id: 'WOOL' }
];

const clotheSizeOptions = [
  { name: '3XS', id: 'XXXS' },
  { name: '2XS', id: 'XXS' },
  { name: 'XS', id: 'XS' },
  { name: 'XS/S', id: 'XS_S' },
  { name: 'S', id: 'S' },
  { name: 'S/M', id: 'S_M' },
  { name: 'M', id: 'M' },
  { name: 'M/L', id: 'M_L' },
  { name: 'L', id: 'L' },
  { name: 'L/XL', id: 'L_XL' },
  { name: 'XL', id: 'XL' },
  { name: '2XL', id: 'XXL' },
  { name: '3XL', id: 'XXXL' },
  { name: '4XL', id: 'XXXXL' },
  { name: 'One Size', id: 'ONE_SIZE' },
  { name: '0-3 months old', id: 'M0_3' },
  { name: '3-6 months old', id: 'M3_6' },
  { name: '6-9 months old', id: 'M6_9' },
  { name: '9-12 months old', id: 'M9_12' },
  { name: '1 year old', id: 'Y1' },
  { name: '2 years old', id: 'Y2' },
  { name: '3 years old', id: 'Y3' },
  { name: '4 years old', id: 'Y4' },
  { name: '5 years old', id: 'Y5' },
  { name: '6 years old', id: 'Y6' },
  { name: '7 years old', id: 'Y7' },
  { name: '8 years old', id: 'Y8' },
  { name: '9 years old', id: 'Y9' },
  { name: '10 years old', id: 'Y10' },
  { name: '11 years old', id: 'Y11' },
  { name: '12 years old', id: 'Y12' },
  { name: '13 years old', id: 'Y13' },
  { name: '14 years old', id: 'Y14' },
  { name: '15 years old', id: 'Y15' },
  { name: '16 years old', id: 'Y16' }
];

const clotheColorOptions = [
  { name: '🏼 Beige', id: 'BEIGE' },
  { name: '⚫ Black', id: 'BLACK' },
  { name: '🔵 Blue', id: 'BLUE' },
  { name: '🥉 Bronze', id: 'BRONZE' },
  { name: '🤎 Brown', id: 'BROWN' },
  { name: 'Coral', id: 'CORAL' },
  { name: '🔶 Gold', id: 'GOLD' },
  { name: '🟢 Green', id: 'GREEN' },
  { name: '🤍 Grey', id: 'GREY' },
  { name: '🔮 Magenda', id: 'MAGENDA' },
  { name: '🌈 Mixed colours', id: 'MIXED_COLOURS' },
  { name: '🟠 Orange', id: 'ORANGE' },
  { name: '🎀 Pink', id: 'PINK' },
  { name: '🟣 Purple', id: 'PURPLE' },
  { name: '🔴 Red', id: 'RED' },
  { name: '🤍 Silver', id: 'SILVER' },
  { name: '🌫 Transparent', id: 'TRANSPARENT' },
  { name: '⚪ White', id: 'WHITE' },
  { name: '🟡 Yellow', id: 'YELLOW' }
];

const clotheOccasionOptions = [
  { name: 'BEACH', id: 'BEACH' },
  { name: 'CASUAL', id: 'CASUAL' },
  { name: 'COSY', id: 'COSY' },
  { name: 'DATE', id: 'DATE' },
  { name: 'EVENING', id: 'EVENING' },
  { name: 'FESTIVAL', id: 'FESTIVAL' },
  { name: 'FORMAL', id: 'FORMAL' },
  { name: 'FUN', id: 'FUN' },
  { name: 'LAZY', id: 'LAZY' },
  { name: 'OUTDOORS', id: 'OUTDOORS' },
  { name: 'PARTY', id: 'PARTY' },
  { name: 'RAIN', id: 'RAIN' },
  { name: 'SCHOOL', id: 'SCHOOL' },
  { name: 'SPORTS', id: 'SPORTS' },
  { name: 'UNIVERSITY', id: 'UNIVERSITY' },
  { name: 'WORK', id: 'WORK' }
];

const clotheMaterialOptions = [
  { name: 'ACETATE', id: 'ACETATE' },
  { name: 'ACRYLIC', id: 'ACRYLIC' },
  { name: 'COTTON', id: 'COTTON' },
  { name: 'DENIM', id: 'DENIM' },
  { name: 'ELASTANE', id: 'ELASTANE' },
  { name: 'FAUX_LEATHER', id: 'FAUX_LEATHER' },
  { name: 'FEATHER', id: 'FEATHER' },
  { name: 'JERSEY', id: 'JERSEY' },
  { name: 'LINEN', id: 'LINEN' },
  { name: 'LYCRA', id: 'LYCRA' },
  { name: 'MOHAIR', id: 'MOHAIR' },
  { name: 'NYLON', id: 'NYLON' },
  { name: 'POLYESTER', id: 'POLYESTER' },
  { name: 'RAYON', id: 'RAYON' },
  { name: 'REAL_LEATHER', id: 'REAL_LEATHER' },
  { name: 'SATIN', id: 'SATIN' },
  { name: 'SILK', id: 'SILK' },
  { name: 'TWEED', id: 'TWEED' },
  { name: 'VELVET', id: 'VELVET' },
  { name: 'VISCOSE', id: 'VISCOSE' },
  { name: 'WOOL', id: 'WOOL' }
];

const clothePatternOptions = [
  // Hidden from the options:
  // { name: 'CHEQUERED', id: 'CHEQUERED' },
  { name: 'Animal print', id: 'ANIMAL_PRINT' },
  { name: 'Dotted', id: 'DOTTED' },
  { name: 'Floral', id: 'FLORAL' },
  { name: 'Patterned', id: 'PATTERNED' },
  { name: 'Plaid', id: 'PLAID' },
  { name: 'Plain', id: 'PLAIN' },
  { name: 'Stripped', id: 'STRIPPED' },
  { name: 'Stamp', id: 'STAMP' }
];

const clotheSeasonOptions = [
  { name: '🍁❄️ Autumn/Winter ', id: 'AUTUMN_WINTER' },
  { name: '🌼🏖️ Spring/Summer', id: 'SPRING_SUMMER' },
  { name: '🍁❄️🌼🏖️ All year', id: 'ALL_YEAR' }
];

const clotheSleeveTypeOptions = [
  // { name: 'Batwing', id: 'BATWING' },
  // { name: 'Bell', id: 'BELL' },
  // { name: 'Bishop', id: 'BISHOP' },
  // { name: 'Cap', id: 'CAP' },
  // { name: 'Cuffed', id: 'CUFFED' },
  // { name: 'Dolman', id: 'DOLMAN' },
  // { name: 'Juliette', id: 'JULIETTE' },
  { name: '🥼 Long', id: 'LONG_SLEEVE' },
  // { name: 'Petal', id: 'PETAL' },
  // { name: 'Puffed', id: 'PUFFED' },
  // { name: 'Raglan', id: 'RAGLAN' },
  { name: '👕 Short', id: 'SHORT_SLEEVE' },
  { name: '🎽 Sleeveless', id: 'SLEEVELESS' }
  // { name: 'Split', id: 'SPLIT' }
];

const clotheNeckLineOptions = [
  { name: 'Asymmetric', id: 'ASYMMETRIC' },
  { name: 'Bateu', id: 'BATEAU' },
  { name: 'Cowl', id: 'COWL' },
  { name: 'Grecian', id: 'GRECIAN' },
  { name: 'Halter strap', id: 'HALTER_STRAP' },
  { name: 'Halter', id: 'HALTER' },
  { name: 'High neck', id: 'HIGH_NECK' },
  { name: 'Illusion', id: 'ILLUSION' },
  { name: 'Jewel', id: 'JEWEL' },
  { name: 'Off shouldenr', id: 'OFF_SHOULDER' },
  { name: 'Queen Anne', id: 'QUEEN_ANNE' },
  { name: 'Sabrina', id: 'SABRINA' },
  { name: 'Scoop', id: 'SCOOP' },
  { name: 'Spaghetti strap', id: 'SPAGHETTI_STRAP' },
  { name: 'Square', id: 'SQUARE' },
  { name: 'Straight across', id: 'STRAIGHT_ACCROSS' },
  { name: 'Sweetheart', id: 'SWEETHEART' },
  { name: 'V neck', id: 'V_NECK' }
];

const clotheBodyFitOptions = [
  { name: 'Regular fit', id: 'REGULAR_FIT' },
  { name: 'Skinny fit', id: 'SKINNY_FIT' },
  { name: 'Slim fit', id: 'SLIM_FLIT' },
  { name: 'Tapered fit', id: 'TAPERED_FIT' },
  { name: 'Ultra slim', id: 'ULTRA_SLIM' }
];

export const productAttributes = [
  { title: 'Condition', value: "condition", type: 'options', options: conditionOptions },
  { title: 'Vintage', value: "vintage", type: 'boolean' },
  { title: 'Rare', value: "rare", type: 'boolean' },
  { title: 'Cirkel reworked', value: "cirkelReworked", type: 'boolean' },
  { title: 'Partner reworked', value: "partnerReworked", type: 'boolean' },
  { title: 'Cirkel approved', value: "cirkelApproved", type: 'boolean' },
  { title: 'Cirkel favorite', value: "cirkelFavorite", type: 'boolean' },
  { title: 'Hot', value: "hot", type: 'boolean' },
  { title: 'Dry Cleaning', value: "dryCleaning", type: 'boolean' },
  { title: 'Popular', value: "popular", type: 'boolean' },
  { title: 'Great value', value: "greatValue", type: 'boolean' },
  { title: 'Designer brand', value: "designerBrand", type: 'boolean' },
  { title: 'Handmade', value: "handmade", type: 'boolean' },
  { title: 'Cirkel Partner', value: "cirkelPartner", type: 'string' },
  { title: 'In-store display', value: "inStoreDisplay", type: 'boolean' },
  { title: 'Has defect', value: "hasDefect", type: 'boolean' },
  { title: 'Influencer', value: "influencer", type: 'boolean' },
  { title: 'Collectible', value: "collectible", type: 'boolean' },
  { title: 'Has story', value: "hasStory", type: 'boolean' },
  { title: 'On social', value: "onSocial", type: 'string' },
  { title: 'On flash sale', value: "onFlashSale", type: 'boolean' },
  { title: 'Trending', value: "trending", type: 'boolean' },
  { title: 'Campaign', value: "campaign", type: 'boolean' },
  { title: 'Event', value: "event", type: 'boolean' },
  { title: 'Country', value: "country", type: 'string' },
  { title: 'Available soon', value: "availableSoon", type: 'boolean' },
  { title: 'Season clearence', value: "seasonClearance", type: 'boolean' },
  { title: 'Season essential', value: "seasonEssential", type: 'boolean' },
  { title: 'Daily pick', value: "dailyPick", type: 'boolean' },
  { title: 'Weekly pick', value: "weeklyPick", type: 'boolean' },
  { title: 'Monthly pick', value: "monthlyPick", type: 'boolean' },
  { title: 'Low resell value', value: "lowResellValue", type: 'boolean' },
  { title: 'Bad condition', value: "badCondition", type: 'boolean' }
];

export const bookAttributes = [
  { title: 'Pages', value: 'pages', type: 'number', filter: 'range' },
  { title: 'Language', value: 'language', type: 'options', options: languageOptions },
  { title: 'Author', value: 'author', type: 'string' },
  { title: 'Year', value: 'year', type: 'number', filter: 'range'},
  { title: 'Cover', value: 'cover', type: 'options', options: coverOptions },
  { title: 'Best seller', value: 'bestseller', type: 'boolean' },
  { title: 'Awarded', value: 'awarded', type: 'boolean' }
];

export const accessoriesAttributes = [
  { title: 'Accessory size', value: 'accessorySize', type: 'options', options: accessorySizeOptions },
  { title: 'Accessory recommended size', value: 'accessoryRecommendedSize', type: 'options', options: accessorySizeOptions },
  { title: 'Accessory brand', value: 'accessoryBrand', type: 'string' },
  { title: 'Accessory color', value: 'accessoryColor', type: 'options', options: accessoryColorOptions },
  { title: 'Accessory occasion', value: 'accessoryOccasion', type: 'options', options: accessoryOccasionOptions },
  { title: 'Accessory material', value: 'accessoryMaterial', type: 'options', options: accessoryMaterialOptions }
];

export const clothesAttributes = [
  { title: 'Cloth size', value: 'clotheSize', type: 'options', options: clotheSizeOptions },
  { title: 'Cloth estimated size', value: 'clotheRecommendedSize', type: 'options', options: clotheSizeOptions },
  { title: 'Cloth brand', value: 'clotheBrand', type: 'string' },
  { title: 'Cloth color', value: 'clotheColor', type: 'options', options: clotheColorOptions },
  { title: 'Cloth color detail', value: 'colorDetail', type: 'string' },
  { title: 'Cloth occasion', value: 'clotheOccasion', type: 'options', options: clotheOccasionOptions },
  { title: 'Cloth material', value: 'clotheMaterial', type: 'options', options: clotheMaterialOptions },
  { title: 'Pattern', value: 'pattern',  type: 'options', options: clothePatternOptions },
  { title: 'Season', value: 'season', type: 'options', options: clotheSeasonOptions },
  { title: 'Shoulder to shoulder', value: 'shoulderToShoulder', type:'number', filter: 'range' },
  { title: 'Pit to pit', value: 'pitToPit', type:'number', filter: 'range' },
  { title: 'Length', value: 'length', type:'number', filter: 'range' },
  { title: 'Length of back', value: 'lengthOfBack', type:'number', filter: 'range' },
  { title: 'Waist', value: 'waist', type:'number', filter: 'range' },
  { title: 'Sleeve type', value: 'sleeveType', type: 'options', options: clotheSleeveTypeOptions },
  { title: 'Neck line', value: 'neckline', type: 'options', options: clotheNeckLineOptions },
  { title: 'Hip', value: 'hip', type: 'number', filter: 'range' },
  { title: 'Rise', value: 'rise', type:'number', filter: 'range' },
  { title: 'Body fit', value: 'bodyFit', type: 'options', options: clotheBodyFitOptions }
];
