import {
  Menu,
  usePermissions
} from 'react-admin';

import SubMenu from './SubMenu';

import {
  Book as BookIcon,
  People as UserIcon,
  LocalMall as LocalMallIcon,
  SubdirectoryArrowRight as SubCategoryIcon,
  AirportShuttle as AirportShuttleIcon,
  Storefront as StorefrontIcon,
  LocalShipping as LocalShippingIcon,
  GTranslate as GTranslateIcon,
  Category as CategoryIcon,
  PointOfSale as PointOfSaleIcon,
  AdminPanelSettings as AdminPanelSettingsIcon,
  Calculate as CalculateIcon,
  Inventory2 as InventoryIcon
} from '@mui/icons-material';

const SoonBadge = () => (
  <span style={{ marginLeft: '8px', fontSize: "12px", color: 'white', background: "grey", borderRadius: "14px", padding: "2px 6px 2px 6px", fontWeight:"600" }}>Soon!</span>
);


const NewBadge = () => (
  <span style={{ marginLeft: '8px', fontSize: "12px", color: 'white', background: "green", borderRadius: "14px", padding: "2px 6px 2px 6px", fontWeight:"600" }}>New!</span>
);

export const SideMenu = () => {
  const { permissions } = usePermissions();

  return (
    <Menu>
      <Menu.DashboardItem to="/" label="Dashboard">
        Dashboard
      </Menu.DashboardItem>
      <Menu.Item to="/users" primaryText="Users" leftIcon={<UserIcon />} />
      {permissions === 'SUPER_ADMIN' && (<Menu.Item to="/bags" primaryText="Bags" leftIcon={<LocalMallIcon />} />)}
      <Menu.Item to="/storage" primaryText="Storage" leftIcon={<InventoryIcon />}>
        Storage<NewBadge />
      </Menu.Item>
      {permissions === 'SUPER_ADMIN' && (<Menu.Item to="/shipments/time" primaryText="Van Schedule" leftIcon={<AirportShuttleIcon />}/>)}
      <Menu.Item to="/categories" primaryText="Categories" leftIcon={<CategoryIcon />} />
      <Menu.Item to="/subcategories" primaryText="Subcategories" leftIcon={<SubCategoryIcon />} />
      <Menu.Item to="/products" primaryText="All Products" leftIcon={<StorefrontIcon />} />

      {permissions === 'SUPER_ADMIN' && (<Menu.Item to="/orders" primaryText="Orders" leftIcon={<LocalMallIcon />} />)}
      <Menu.Item to="/pickups" primaryText="Pickups" leftIcon={<LocalShippingIcon />} />
      {permissions === 'SUPER_ADMIN' && (<Menu.Item to="/deliveries" primaryText="Bag Deliveries" leftIcon={<LocalShippingIcon />} />)}
      {permissions === 'SUPER_ADMIN' && (<Menu.Item to="/translations" primaryText="Translations" leftIcon={<GTranslateIcon />} />)}
      {permissions === 'SUPER_ADMIN' && (<Menu.Item to="/payment-methods" primaryText="Payment Methods" leftIcon={<LocalMallIcon />} />)}
      <SubMenu primaryText="Analytics" isDropdownOpen={false} leftIcon={<BookIcon />}>
        <Menu.Item to="/analytics/allProducts" primaryText="All Products" leftIcon={<StorefrontIcon />} >
          All Products
        </Menu.Item>
        <Menu.Item to="/comingSoon" primaryText="Clothes" leftIcon={<StorefrontIcon />}>
          Clothes<SoonBadge />
        </Menu.Item>
        <Menu.Item to="/analytics/genders" primaryText="Genders" leftIcon={<StorefrontIcon />}>
          Genders
        </Menu.Item>
        <Menu.Item to="/comingSoon" primaryText="Seasons" leftIcon={<StorefrontIcon />}>
          Seasons<SoonBadge />
        </Menu.Item>
        <Menu.Item to="/comingSoon" primaryText="Categories" leftIcon={<StorefrontIcon />}>
          Categories<SoonBadge />
        </Menu.Item>
        <Menu.Item to="/comingSoon" primaryText="Subcategories" leftIcon={<StorefrontIcon />}>
          Subcategories<SoonBadge />
        </Menu.Item>
      </SubMenu>
      {permissions === 'SUPER_ADMIN' &&
        <Menu.Item to="/sales" primaryText="Sales" leftIcon={<PointOfSaleIcon />}>
          Sales
        </Menu.Item>}
      {permissions === 'SUPER_ADMIN' &&
        <Menu.Item to="/comingSoon" primaryText="Finances" leftIcon={<CalculateIcon />}>
          Finances<SoonBadge />
        </Menu.Item>}
      {permissions === 'SUPER_ADMIN' &&
        <Menu.Item to="/comingSoon" primaryText="Administration" leftIcon={<AdminPanelSettingsIcon />}>
          Administration<SoonBadge />
        </Menu.Item>}
    </Menu>
  );
}
