import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  EditButton,
  useCreatePath,
  useRecordContext,
  useDataProvider
} from 'react-admin';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { BankIbanCodes, formatUTCDate } from '../../utils';

export const CopyUserEmail = () => {
  const record = useRecordContext();

  return (
    <Button
      style={{minWidth: '32px' }}
      sx={{ "& .MuiButton-startIcon": { marginRight: "0px" }}}
      onClick={() => navigator.clipboard.writeText(record?.email)}
    >
      <ContentCopyIcon fontSize='small' />
    </Button>
  );
}

export const CopyUserId = () => {
  const record = useRecordContext();

  return (
    <Button
      style={{ minWidth: '32px' }}
      sx={{ "& .MuiButton-startIcon": { marginRight: "0px" }}}
      onClick={() => navigator.clipboard.writeText(record?.id)}
    >
      <ContentCopyIcon fontSize='small' />
    </Button>
  );
}

export const GetBankName = () => {
  const record = useRecordContext();

  if (record && record.iban) {
    const bankIdentifier = record.iban.substring(4,7);
    const bankName = BankIbanCodes[bankIdentifier] || 'unknown!';
    return 'Bank: ' + bankName;
  }
  return 'IBAN not set!';
}

export const GetUsersPickups = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const createPath = useCreatePath();
  const [userPickups, setUserPickups] = useState([]);

  useEffect(() => {
    (async () => {
      let userPickups = [];
      try {
        userPickups = await dataProvider.getUserPickups(record.id);
      } catch (error) {
        console.warn('Error: ', error)
      }
      setUserPickups(userPickups.data);
    })(record)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record])
  return (
    <table style={{width: '100%'}}>
      <thead>
        <tr>
          <th>#</th>
          <th>Pickup ID</th>
          <th>Status</th>
          <th>Start Time</th>
          <th>End Time</th>
          <th>No of bags</th>
          <th>Phone number</th>
          <th>Post Code</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {userPickups.map((userPickup, index) => {
          const pickupPath = createPath({ resource: 'pickups', type: 'edit', id: userPickup?.id });

          return (
            <tr key={index}>
              <td key={'#'}>{index + 1}</td>
              <td key={'pickupId'}>{userPickup.id}</td>
              <td key={'Status'}>{userPickup.bagPickupStatus && userPickup.bagPickupStatus.bagPickupStatusName}</td>
              <td key={'startTime'}>{userPickup.shipmentTime.startDate ? formatUTCDate(userPickup.shipmentTime.startDate) : 'Delivered in Store'}</td>
              <td key={'endTime'}>{userPickup.shipmentTime.endDate ? formatUTCDate(userPickup.shipmentTime.endDate) : 'Delivered in Store'}</td>
              <td key={'numberOfBagsToReturn'}>{userPickup.numberOfBagsToReturn}</td>
              <td key={'phoneNumber'}>{userPickup.userAddressSnapshot.phoneNumber}</td>
              <td key={'postcode'}>{userPickup.userAddressSnapshot.postcode}</td>
              <td key={'action'}>
                <EditButton component={Link} variant="contained" to={pickupPath} label="Edit" />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export const GetUsersOrders = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [userOrders, setUserOrders] = useState([]);
  const createPath = useCreatePath();

  useEffect(() => {
    (async () => {
      let orders = [];
      try {
        orders = await dataProvider.getUserOrders(record.id);
      } catch (error) {
        console.warn('Error: ', error)
      }
      if (!record || !orders || !orders.data) return;
      setUserOrders(orders?.data);
    })(record)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record])
  return (
    <table style={{width: '100%'}}>
      <thead>
        <tr>
          <th>#</th>
          <th>Order ID</th>
          <th>Status</th>
          <th>Time</th>
          <th>Total Price</th>
          <th>Phone</th>
          <th>Edit</th>
        </tr>
      </thead>
      <tbody>
        {userOrders.map((userOrder, index) => {
          const orderPath = createPath({ resource: 'orders', type: 'edit', id: userOrder?.id });
          return userOrder && (
            <tr key={index}>
              <td key={'#'}>{index + 1}</td>
              <td key={'OrderId'}>{userOrder.code}</td>
              <td key={'Status'}>{userOrder?.orderStatus?.orderStatusName}</td>
              <td key={'Time'}>{userOrder.createdAt ? formatUTCDate(userOrder.createdAt) : "-"}</td>
              <td key={'totalPrice'}>{userOrder.totalPrice} €</td>
              <td key={'phoneNumber'}>{userOrder?.userAddressSnapshot?.phoneNumber}</td>
              <td key={'action'}>
                <EditButton component={Link} variant="contained" to={orderPath} label="Edit" />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export const GetAvailableBags = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [noOfBags, seNoOfBags] = useState(0);

  useEffect(() => {
    (async () => {
      let response = [];
      try {
        response = await dataProvider.getUserBags(record && record.id);
      } catch (error) {
        console.warn('Error: ', error)
      }
      seNoOfBags(response && response.numberOfBags);
    })(record)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record])

  return (
    <div>
      Available bags: <h1>{noOfBags}</h1>
    </div>
  );
}
