import { Fragment, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { List, ListItem, ListItemText, Collapse, useTheme } from '@mui/material';
import { useTranslate, useSidebarState } from 'react-admin';

export const SubMenu = (props) => {
  const { isDropdownOpen = false, primaryText, leftIcon, children } = props;
  const translate = useTranslate();
  const [open] = useSidebarState();
  const [isOpen, setIsOpen] = useState(isDropdownOpen);
  const theme = useTheme(); // Use the useTheme hook to access the current theme

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Fragment>
      <ListItem
        dense
        onClick={handleToggle}
        sx={{
          paddingLeft: '1rem',
          // Adjust color based on the theme mode
          color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.54)',
        }}
      >
        {isOpen ? <ExpandMoreIcon /> : leftIcon}
        <ListItemText
          inset
          disableTypography
          primary={translate(primaryText)}
          sx={{
            paddingLeft: 2,
            fontSize: '1rem',
            // Adjust color based on the theme mode
            color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.6)',
          }}
        />
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={open ? {
            paddingLeft: '25px',
            transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
          } : {
            paddingLeft: 0,
            transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
          }}
        >
          {children}
        </List>
      </Collapse>
    </Fragment>
  )
}

export default SubMenu;
