import React from 'react';
import { Title } from 'react-admin';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Widget from 'src/common/widget/widget';
import SeasonsWidget from 'src/Dashboard/widgets/products/seasons';

import {
  SEASONS_ALL,
  SEASONS_MAN,
  SEASONS_WOMAN,
  SEASONS_KID
} from 'src/Dashboard/widgets/products/queries';

import styles from 'src/Dashboard/styles.module.scss';

const Genders = () => {
  return (
    <Card>
      <Title title="Welcome to Cirkel Admin" />
      <CardContent>
        <span className={styles.widgetsTitle}>Gender Metrics</span>
        <div className={styles.widgetsRow}>
          <Widget title="All Genders" subtitle={"Under Sell"}>
            {SeasonsWidget(SEASONS_ALL)}
          </Widget>
          <Widget title="Man" subtitle={"Under Sell"}>
            {SeasonsWidget(SEASONS_MAN)}
          </Widget>
          <Widget title="Woman" subtitle={"Under Sell"}>
            {SeasonsWidget(SEASONS_WOMAN)}
          </Widget>
          <Widget title="Kid" subtitle={"Under Sell"}>
            {SeasonsWidget(SEASONS_KID)}
          </Widget>
        </div>
      </CardContent>
    </Card>
  );
};

export default Genders;