import React from 'react';
import { Resource } from 'react-admin';

import { BagEdit, BagList, BagShow } from './views';

const Bags = () => {
  return (
    <Resource
      name="bags"
      options={{ label: 'Bag stock' }}
      list={BagList}
      edit={BagEdit}
      show={BagShow}
    />
  );
}

export default Bags;
