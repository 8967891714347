import React from 'react';
import {
  Resource,
  List,
  Datagrid,
  TextField,
  Edit,
  Create,
  Show,
  Filter,
  SimpleForm,
  ImageInput,
  RichTextField,
  ImageField,
  TextInput,
  SimpleShowLayout,
  Labeled
} from 'react-admin';

import { BackgroundImageField } from '../../utils';
import { AdminPagination } from '../../common/AdminPagination';

export const CategoryList = props => (
  <List pagination={<AdminPagination />} perPage={25} {...props} bulkActionButtons={false} filters={<CategoriesFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="Category ID" />
      <BackgroundImageField firstOf uuid label="Featured Image" resource="categories" className="as-image rounded small" />
      <TextField source="categoryName" label="Title" />
      <RichTextField source="categoryDescription" label="Description" />
    </Datagrid>
  </List>
);

const CategoriesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Title" source="categoryName" alwaysOn />
    <TextInput label="ID" source="id" alwaysOn />
    <TextInput label="Description" source="categoryDescription" alwaysOn />
  </Filter>
  );

export const CategoryShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ImageField source="image" title="Featured Image" />
      <TextField source="categoryName" />
      <RichTextField source="categoryDescription" />
    </SimpleShowLayout>
  </Show>
);

export const CategoryEdit = props => (
  <Edit {...props}>
    <SimpleForm redirect="false">
      <Labeled label="Category ID:">
        <TextField source="id" />
      </Labeled>
      <TextInput source="categoryName" />
      <TextInput source="slug" />
      <div className="helper-text">* No capital letters, special characters and spaces</div>
      <ImageInput  source="images" accept="image/jpeg" maxSize={500000}>
        <ImageField source="data" />
      </ImageInput>
      <div className="helper-text">* The image file must be named "1.jpeg"</div>
      <div className="helper-text">** Max size 500kb</div>
      <TextInput multiline source="categoryDescription" />
    </SimpleForm>
  </Edit>
);

export const CategoryCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="categoryName" />
      <TextInput source="slug" />
      <div className="helper-text">* No capital letters, special characters and spaces allowed</div>
      <TextInput source="categoryDescription" />
    </SimpleForm>
  </Create>
);

const Categories = () => {
  return (
    <Resource
      name="categories"
      options={{ label: 'Categories' }}
      list={CategoryList}
      edit={CategoryEdit}
      create={CategoryCreate}
      show={CategoryShow}
    />
  );
};

export default Categories;
