import React, { Fragment } from 'react';

import {
  AutocompleteInput,
  BooleanInput,
  BulkUpdateButton,
  Button,
  ChipField,
  CreateButton,
  DateField,
  DateTimeInput,
  EditButton,
  ExportButton,
  Filter,
  FilterButton,
  FunctionField,
  List,
  NumberField,
  NumberInput,
  ReferenceArrayField,
  ReferenceField,
  ReferenceInput,
  SelectColumnsButton,
  SelectInput,
  SingleFieldList,
  TextField,
  TextInput,
  TopToolbar,
  useDataProvider,
  useListContext,
  useRefresh,
  usePermissions,
  useRecordContext,
  Datagrid
} from 'react-admin';

import { Chip } from '@mui/material';
import { AdminPagination } from 'src/common/AdminPagination';
import { Switch } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { CopyProductSKU } from 'src/resources/Products/common';

import {
  availableProductStatus,
  availableGenders,
  parseSKU,
  parseProductName,
  parseUID,
  BackgroundImageField
} from 'src/utils';

import { productAttributes, bookAttributes, accessoriesAttributes, clothesAttributes } from 'src/utils/productAttributes';
import RangeInput from 'src/common/RangeInput';

import styles from 'src/resources/Products/styles.module.scss';

const ProductPanel = () => {
  const record = useRecordContext();

  return (
    <div>Storage Position: {record.storagePosition || "No position yet!"}</div>
  );
};

const CopyProductName = () => {
  const record = useRecordContext();

  return (
    <Button
      style={{minWidth: '32px'}}
      sx={{ "& .MuiButton-startIcon": { marginRight: "0px" }}}
      onClick={() => navigator.clipboard.writeText(record?.name)}
    >
      <ContentCopyIcon fontSize='small' />
    </Button>
  );
}

const InStoreSwitch = (props) => {
  const record = useRecordContext(props);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const handleChange = (event) => {
    const newValue = event.target.checked;
    dataProvider.update('products', { id: record.id, data: { ...record, inStoreDisplay: newValue }})
      .then(() => {
        refresh();
      })
  };

  return (
    <Switch
      checked={record?.inStoreDisplay || false}
      onChange={handleChange}
      color="success"
    />
  );
};

const FeaturedButton = (props) => {
  const record = useRecordContext(props);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();


  const handleChange = () => {
    const newValue = !record.featured;
    dataProvider.update('products', { id: record.id, data: { ...record, featured: newValue }})
      .then(() => {
        refresh();
      })
  };

  return <span className={styles.featured} onClick={handleChange}>{record?.featured ? '💛' : '🤍'}</span>;
};

const SeasonClearanceSwitch = (props) => {
  const record = useRecordContext(props);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const handleChange = (event) => {
    const newValue = event.target.checked;
    dataProvider.update('products', { id: record.id, data: { ...record, seasonClearance: newValue }})
      .then(() => {
        refresh();
      })
  };

  return (
    <Switch
      checked={record?.seasonClearance || false}
      onChange={handleChange}
      color="success"
    />
  );
};

const renderChip = (record) => {
  if (!record) {
    return null;
  }

  const value = record.productStatusName;
  let chipProps = {
    label: availableProductStatus.find(status => status?.id === value)?.name || value,
    size: "large", // Default size, can be customized
  };

  return <Chip {...chipProps} />;
};

const ProductListActions = () => {
  const { permissions } = usePermissions();

  return (
    <TopToolbar>
      <SelectColumnsButton />
      <FilterButton filters={ProductFilters()} />
      <CreateButton />
      {permissions === 'SUPER_ADMIN' && (<ExportButton maxResults={99999999} />)}
    </TopToolbar>
  );
};

const ProductFilters = (props) => {
  return [
    <TextInput label="Name" source="name" alwaysOn parse={value => parseProductName(value)} />,
    <TextInput label="ID" source="id" parse={value => parseUID(value)} />,
    <TextInput label="SKU" source="code" alwaysOn parse={value => parseSKU(value)} />,
    <TextInput label="Pickup ID" source="bagPickupId" />,
    <SelectInput label="Status" source="productStatusName" choices={availableProductStatus} alwaysOn />,
    <ReferenceInput
      label="Category"
      reference="categories"
      sort={{ field: 'categoryName', order: 'ASC' }}
      allowEmpty
      alwaysOn
    >
      <AutocompleteInput optionText="categoryName" />
    </ReferenceInput>,
    <ReferenceInput
      label="Subcategory"
      reference="subcategories"
      sort={{ field: 'subcategoryName', order: 'ASC' }}
      allowEmpty
      perPage={999}
      alwaysOn
    >
      <SelectInput optionText="subcategoryName" />
    </ReferenceInput>,
    <SelectInput label="Gender" source="gender" choices={availableGenders}/>,
    <RangeInput label="Published at" source="publishedAt" />,
    <RangeInput label='Sold at' source='soldAt' />,
    <TextInput label='Storage Position' source='storagePosition' />,
    <NumberInput label='Quantity' source="quantity" defaultValue={1} />,
    <BooleanInput source="featured" />,
    <BooleanInput source="lastCall" />,
    <BooleanInput source="sellerSharePaid" />,
    <BooleanInput label="Cleaned" source="cleaned" />,
    <BooleanInput label="Fixed" source="fixed" />,

    ...[...productAttributes, ...bookAttributes, ...clothesAttributes, ...accessoriesAttributes].map(attribute => {
      let element = '';
      if (attribute.filter === 'range') {
        element = <RangeInput key={attribute.title} label={attribute.title} source={`${attribute.value}`} type={attribute.type} />
      } else if (attribute.type === 'options') {
        element = <SelectInput key={attribute.title} label={attribute.title} source={attribute.value} choices={attribute.options} />
      } else if (attribute.type === 'boolean') {
        element = <Chip key={attribute.title} source={attribute.value} label={attribute.title} defaultValue={true} />
      } else if (attribute.type === 'string') {
        element = <TextInput key={attribute.title} label={attribute.title} source={attribute.value} />
      } else if (attribute.type === 'datetime') {
        element = <DateTimeInput key={attribute.title} label={attribute.title} source={attribute.value} locales="el-GR" />
      } else if (attribute.type === 'number') {
        element = <NumberInput key={attribute.title} label={attribute.title} source={attribute.value} />
      }
      return element;
    })
  ]
};

const ProductFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Name" source="name" alwaysOn parse={value => parseProductName(value)} />
      <TextInput label="ID" source="id" parse={value => parseUID(value)} />
      <TextInput label="SKU" source="code" alwaysOn parse={value => parseSKU(value)} />
      <TextInput label="Pickup ID" source="bagPickupId" />
      <SelectInput label="Status" source="productStatusName" choices={availableProductStatus} alwaysOn />
      <NumberInput label="Price" min={0} source="price" alwaysOn />
      <NumberInput label="Sale Price" min={0} source="salePrice" />
      <ReferenceInput
        label="Category"
        source="categoryId"
        reference="categories"
        sort={{ field: 'categoryName', order: 'ASC' }}
        filterToQuery={searchText => ({ categoryName: searchText })}
        allowEmpty
        alwaysOn
      >
        <AutocompleteInput optionText="categoryName" />
      </ReferenceInput>
      <ReferenceInput
        label="Subcategory"
        source="subcategoriesId"
        reference="subcategories"
        sort={{ field: 'subcategoryName', order: 'ASC' }}
        allowEmpty
        perPage={999}
        alwaysOn
      >
        <SelectInput optionText="subcategoryName" />
      </ReferenceInput>
      <SelectInput label="Gender" source="gender" choices={availableGenders}/>
      <RangeInput label="Published at" source="publishedAt" />
      <RangeInput label='Sold at' source='soldAt' />
      <TextInput label='Storage Position' source='storagePosition' />
      <NumberInput label='Quantity' source="quantity" defaultValue={1} />
      <BooleanInput source="featured" />
      <BooleanInput source="lastCall" />
      <BooleanInput source="sellerSharePaid" />
      <BooleanInput label="Cleaned" source="cleaned" />
      <BooleanInput label="Fixed" source="fixed" />

      {[...productAttributes, ...bookAttributes, ...clothesAttributes, ...accessoriesAttributes].map(attribute => {
        let element = '';
        if (attribute.filter === 'range') {
          element = <RangeInput key={attribute.title} label={attribute.title} source={`${attribute.value}`} type={attribute.type} />
        } else if (attribute.type === 'options') {
          element = <SelectInput key={attribute.title} label={attribute.title} source={attribute.value} choices={attribute.options} />
        } else if (attribute.type === 'boolean') {
          element = <BooleanInput key={attribute.title} source={attribute.value} label={attribute.title} defaultValue={true} />
        } else if (attribute.type === 'string') {
          element = <TextInput key={attribute.title} label={attribute.title} source={attribute.value} />
        } else if (attribute.type === 'datetime') {
          element = <DateTimeInput key={attribute.title} label={attribute.title} source={attribute.value} locales="el-GR" />
        } else if (attribute.type === 'number') {
          element = <NumberInput key={attribute.title} label={attribute.title} source={attribute.value} />
        }
        return element;
      })}
    </Filter>
  )
};

const ProductBulkActionButtons = () => {
  const { selectedIds, data } = useListContext();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const handleBulkInStoreClick = () => {
    const selectedRecords = data.filter(record => selectedIds.includes(record.id));
    const updatedRecords = selectedRecords.map(record => ({ ...record, inStoreDisplay: true }));
    dataProvider.updateMany('products', updatedRecords)
      .then(() => {
        refresh();
      })
  };

  const handleBulkDonateClick = () => {
    const selectedRecords = data.filter(record => selectedIds.includes(record.id));
    const updatedRecords = selectedRecords.map((record) => (
      { ...record,
        productStatusName: "DONATED",
        price: 0,
        salePrice: 0,
        suggestedPrice: 0,
        name: record.name + " - Αδυναμία μεταπώλησης",
      }));
    dataProvider.updateMany('products', updatedRecords)
      .then(() => {
        refresh();
      })
  };

  return (
    <Fragment>
      <BulkUpdateButton label="🛍 Add in-store" icon={false} onClick={handleBulkInStoreClick} />
      <BulkUpdateButton label="💝 Donate!" icon={false} onClick={handleBulkDonateClick} />
    </Fragment>
  );
};

export const ProductList = (props) => (
  <List
    pagination={<AdminPagination />}
    perPage={25}
    {...props}
    filters={<ProductFilter />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    actions={<ProductListActions />}
  >
    <Datagrid
      expand={<ProductPanel />}
      rowClick={false}
      bulkActionButtons={<ProductBulkActionButtons />}
    >
      <BackgroundImageField firstOf label="Image" resource="products" />
      {/* <FunctionField render={record => <div className={styles.featuredIcon}>{record && record.featured ? '💛' : '🤍'}</div>} label="💛" /> */}
      <FeaturedButton label="💛" />
      <InStoreSwitch label="In store" />
      <FunctionField render={(record) => record?.storagePosition ? "✅" : "❌"} label="Storage" />
      <FunctionField render={
        () => {
          return (
            <Fragment>
              <CopyProductName />
              <TextField source="name" label="Name" />
            </Fragment>
          )
        }
      }
        label="Name"
      />
      <FunctionField render={
        (record) => {
          return (
            <span>
              {record?.code.toUpperCase()}
              <CopyProductSKU />
            </span>
          )
        }
      }
        source="code"
        label="SKU"
      />
      <EditButton label="Edit" variant="contained"/>
      <FunctionField render={
        (record) => {
          return (
            <Fragment>
              {availableGenders.map((gender, index) => {
                return gender.id === record.gender && (
                  <Chip
                    key={index}
                    sx={{ fontSize: 18 }}
                    label={gender.short}
                    size="medium"
                    color={(gender.id === "MALE" && "secondary") || (gender.id === "FEMALE" && "error") || (gender.id === "UNISEX" && "warning") || (gender.id === "KID" && "success")}
                  />
                );
              })}
            </Fragment>
          );
        }}
        label="Sex"
      />
      <ReferenceField source="categoryId" reference="categories" label="Category">
        <ChipField source="categoryName" />
      </ReferenceField>
      <ReferenceArrayField source="subcategoriesIds" reference="subcategories" sortable={false} label="Subcategory">
        <SingleFieldList>
          <ChipField source="subcategoryName" />
        </SingleFieldList>
      </ReferenceArrayField>
      <NumberField source="price" label="Price" locales="el-GR" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} />
      <NumberField source="salePrice" label="Sale Price" locales="el-GR" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} />
      <SeasonClearanceSwitch label="Clearance" />
      <FunctionField render={record => renderChip(record)} label="Status" />
      <DateField source="createdAt" showTime label="Created" />
      <DateField source="updatedAt" showTime label="Updated" />
      <DateField source="publishedAt" showTime label="Published" />
      <DateField source="soldAt" showTime label="Sold" />
    </Datagrid>
  </List>
);
