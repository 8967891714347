
import { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';

import styles from './styles.module.scss';

const TodaysVanSlotsWidget = () => {

  const dataProvider = useDataProvider();
  const [vanSlotCount, setVanSlotCount] = useState(0);

  useEffect(() => {
    const GetVanSlots = async () => {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Ensures the month is two digits
      const currentDay = currentDate.getDate().toString().padStart(2, '0'); // Ensures the day is two digits
      try {
        const res = await dataProvider.getList("deliveries",
          {
            filter : {
              startDate: currentYear + "-" + currentMonth + "-" + currentDay + "T00:01:00.000Z",
              endDate: currentYear + "-" + currentMonth + "-" + currentDay + "T23:59:00.000Z",
            },
            pagination: { page: 1, perPage: 1 },
          }
        );
        setVanSlotCount(res?.total || res?.data?.length || 0);
      } catch (error) {
        console.warn('Error: ', error);
      }
    };

    GetVanSlots();
  }, [dataProvider]); // Dependency array to avoid infinite loop

  return (
    <div className={styles.countText}>
      {vanSlotCount}
    </div>
  );
};

export default TodaysVanSlotsWidget;
