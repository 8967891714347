import {
  Edit,
  SimpleForm,
  NumberInput,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  DateField,
  SelectInput,
  required
} from 'react-admin';

import { PAYMENT_INTEGRATIONS } from 'src/utils/paymentIntegrations';

export const PaymentMethodsEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <div className="helper-text">Method ID:</div>
      <TextInput source="id" disabled/>
      <div className="helper-text">The name of the payment method:</div>
      <TextInput source="name" validate={[required()]} />
      <div className="helper-text">Payment Integration:</div>
      <SelectInput source="paymentIntegration" choices={PAYMENT_INTEGRATIONS} validate={[required()]} />
      <ReferenceInput
        label="Shipment Type"
        source="shipmentTypeId"
        reference="shipments/types"
        alwaysOn
      >
        <AutocompleteInput optionText="name" validate={[required()]} />
      </ReferenceInput>
      <div className="helper-text">Payment method price (regular):</div>
      <NumberInput source="price" validate={[required()]} />
      <div className="helper-text">Payment method discounted price (after reaching total order cost):</div>
      <NumberInput source="priceDiscounted" validate={[required()]} />
      <div className="helper-text">The name of the image to be displayed on the website:</div>
      <TextInput source="imageName" validate={[required()]} />
      <div className="helper-text">The description of the payment method (for use internally):</div>
      <TextInput source="description" validate={[required()]} />
      <div className="helper-text">Is it active or not:</div>
      <BooleanInput source="active" validate={[required()]} />
      <div className="helper-text">Is on special offer:</div>
      <BooleanInput source="onSpecialOffer" validate={[required()]} />
      <div className="helper-text">Creation date:</div>
      <DateField source="createdAt" showTime label="Created" validate={[required()]} />
      <div className="helper-text">Last update date:</div>
      <DateField source="updatedAt" showTime label="Updated" validate={[required()]} />
    </SimpleForm>
  </Edit>
);
