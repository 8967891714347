import { DateTimeInput, NumberInput } from 'react-admin';
import { useFormContext } from "react-hook-form";

const RangeInput = (props) => {
  const formContext = useFormContext();

  function deleteFilter() {
    formContext.setValue(`${props.source}_range_start`, null);
    formContext.setValue(`${props.source}_range_end`, null);
  }

  return (
    <div>
      {props.type === 'number' ? (
        <div>
          <NumberInput label={`From ${props.label}`} source={`${props.source}_range_start`}  />
          <NumberInput label={`To ${props.label}`} source={`${props.source}_range_end`} />
        </div>
      ) : (
        <div>
          <DateTimeInput label={`From ${props.label}`} source={`${props.source}_range_start`} />
          <DateTimeInput label={`To ${props.label}`} source={`${props.source}_range_end`} />
        </div>
      )}
      <button onClick={deleteFilter}>Reset filter before close</button>
    </div>
  )
};

export default RangeInput;
