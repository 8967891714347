import { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';

import Box from '@mui/material/Box';
import { PieChart } from '@mui/x-charts/PieChart';

const pieParams = { height: 170, width: 340 };
const palette = ['blue', 'red', 'green'];

const ManWomanKidWidget = (params) => {

  const dataProvider = useDataProvider();
  const [manCount, setManCount] = useState(10);
  const [womanCount, setWomanCount] = useState(10);
  const [kidCount, setKidCount] = useState(10);

  useEffect(() => {
    const GetProductCount = async () => {
      try {
        const responseMan = await dataProvider.getList(
          "products",
          params.man
        );
        setManCount(responseMan?.total);
        const responseWoman = await dataProvider.getList(
          "products",
          params.woman
        );
        setWomanCount(responseWoman?.total);
        const responseKid = await dataProvider.getList(
          "products",
          params.kid
        );
        setKidCount(responseKid?.total);
      } catch (error) {
        console.warn('Error: ', error);
      }
    };

    GetProductCount();
  }, [dataProvider, params.kid, params.man, params.woman]); // Dependency array to avoid infinite loop

  return (
    <Box flexGrow={1}>
      <PieChart
        series={[{
          data: [{ value: manCount, label: 'Man' }, { value: womanCount, label: 'Woman' }, { value: kidCount, label: 'Kid' }],
          highlightScope: { faded: 'global', highlighted: 'item' },
          faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
        }]}
        colors={palette}
        slotProps={{
        legend: {
            direction: 'column',
            position: { vertical: 'middle', horizontal: 'right' },
            padding: 40,
        }}}
        {...pieParams}
      />
    </Box>
  );
}

export default ManWomanKidWidget;