
import { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import { BarChart } from '@mui/x-charts/BarChart';
import { getHeaders } from 'src/utils';

const YearlySalesWidget = () => {

  const dataProvider = useDataProvider();
  const [productCount2022, setProductCount2022] = useState(0);
  const [productCount2023, setProductCount2023] = useState(0);
  const [productCount2024, setProductCount2024] = useState(0);

  const baseUrl = "https://server.cirkel.gr/admin/products?page=1&size=1";
  const param2022 = "&soldAt=2022-01-01T00:00:01.000Z&soldAt=2022-12-31T23:59:59.000Z";
  const param2023 = "&soldAt=2023-01-01T00:00:01.000Z&soldAt=2023-12-31T23:59:59.000Z";
  const param2024 = "&soldAt=2024-01-01T00:00:01.000Z&soldAt=2024-12-31T23:59:59.000Z";

  useEffect(() => {
    const GetProductCount = async () => {
      try {
        const response2022 = await fetch (baseUrl + param2022,
          { method: 'GET', headers: getHeaders() })
          .then(res => res.json())
        const response2023 = await fetch (baseUrl + param2023,
          { method: 'GET', headers: getHeaders() })
          .then(res => res.json())
        const response2024 = await fetch (baseUrl + param2024,
          { method: 'GET', headers: getHeaders() })
          .then(res => res.json())
        setProductCount2023(response2023?.total || 0);
        setProductCount2022(response2022?.total || 0);
        setProductCount2024(response2024?.total || 0);
      } catch (error) {
        console.warn('Error: ', error);
      }
    };

    GetProductCount();
  }, [dataProvider]); // Dependency array to avoid infinite loop

  return (
    <BarChart
      xAxis={[{ scaleType: 'band', data: ['2022', '2023', '2024'] }]}
      series={[{ data: [productCount2022, productCount2023, productCount2024] } ]}
      width={500}
      height={300}
    />
  );
};

export default YearlySalesWidget;
