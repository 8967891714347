import React, { Fragment } from 'react';
import { useFormContext } from "react-hook-form";
import ProductAttributes from './ProductAttributes';
import { productAttributes, bookAttributes, accessoriesAttributes, clothesAttributes } from '../../utils/productAttributes'

const booksCategoryId = 4
const accessoriesCategoryId = 2
const clothesCategoryId = 1

export default function ProductAttributesWrapper({ formData }) {
  const { setValue } = useFormContext()

  return (
    <Fragment>
      <ProductAttributes title="All product attributes" options={productAttributes} formData={formData} setValue={setValue} />
      {formData && formData.categoryId === booksCategoryId && (
        <ProductAttributes title="Book attributes" options={bookAttributes} formData={formData} setValue={setValue} />
      )}
      {formData && formData.categoryId === accessoriesCategoryId && (
        <ProductAttributes title="Accessories attributes" options={accessoriesAttributes} setValue={setValue} formData={formData} />
      )}
      {formData && formData.categoryId === clothesCategoryId && (
        <ProductAttributes title="Clothes attributes" options={clothesAttributes} formData={formData} setValue={setValue} />)}
    </Fragment>
  );
}