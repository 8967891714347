import React from 'react';
import {
  Resource,
  List,
  Datagrid,
  Edit,
  Show,
  Filter,
  SimpleForm,
  SimpleShowLayout,
  TabbedForm,
  FormTab,
  NumberField,
  NumberInput,
  Create,
  ChipField,
  DateTimeInput,
  DateField,
  TextField,
  DeleteButton,
  SelectInput,
  FunctionField,
} from 'react-admin';
import { availablePostalCodes, formatUTCDate } from '../../utils';

export const VanSlotsList = props => (
  <List
    bulkActionButtons={false}
    filters={<VanSlotsFilter />}
    perPage={25}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField source="id" label="Van Slot ID" />
      <ChipField source="postalCode" label="Postal code" />
      <FunctionField sortBy="startDate" render={record => <span>{formatUTCDate(record.startDate)}</span>} label="Start date" />
      <FunctionField sortBy="endDate" render={record => <span>{formatUTCDate(record.endDate)}</span>} label="End date" />
      <ChipField source="availableBags" />
      <DateField source="createdAt" showTime locales="el-GR" />
      <DateField source="updatedAt" showTime locales="el-GR" />
    </Datagrid>
  </List>
);

const VanSlotsFilter = (props) => (
  <Filter {...props}>
    <NumberInput source="postalCode" alwaysOn />
    <NumberInput source="availableBags" alwaysOn />
  </Filter>
);

export const VanSlotsCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <SelectInput label="Post Code" source="postalCode" choices={availablePostalCodes} />
      <div className="helper-text">Choose:</div>
      <div className="helper-text">54Χ ΧΧ - Δήμος Θεσσαλονίκης (εκτός Τριανδρίας).</div>
      <div className="helper-text">55Χ ΧΧ - Νότια Προάστια Θεσσαλονίκης και Τριανδρία.</div>
      <div className="helper-text">56Χ ΧΧ - Βόρεια Προάστια Θεσσαλονίκης.</div>
      <div className="helper-text">57Χ ΧΧ - Υπόλοιπο Νομού Θεσσαλονίκης.</div>
      <NumberInput source="availableBags" />
      <div className="helper-text">Suggested value: 10</div>
      <DateTimeInput source="startDate" locales="el-GR" />
      <DateTimeInput source="endDate" locales="el-GR" />
      <div className="helper-text">Van slots should have a duration of 2 to 3 hours.</div>
    </SimpleForm>
  </Create>
);

export const VanSlotsShow = props => (
  <Show {...props}>
    <TabbedForm warnWhenUnsavedChanges redirect={false}>
      <FormTab label="Basic">
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="postalCode" label="Postal code" />
          <FunctionField render={record => <span>{formatUTCDate(record.startDate)}</span>} label="Start date" />
          <FunctionField render={record => <span>{formatUTCDate(record.endDate)}</span>} label="End date" />
          <NumberField source="availableBags" />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
        </SimpleShowLayout>
      </FormTab>
      <FormTab label="Deliveries">
        <span>Coming Soon!</span>
      </FormTab>
    </TabbedForm>
  </Show>
);

export const VanSlotsEdit = props => (
  <Edit {...props}>
    <TabbedForm warnWhenUnsavedChanges redirect={false}>
      <FormTab label="Basic">
        <SimpleForm toolbar={<DeleteButton />} redirect="list">
          <TextField source="id" />
          <TextField source="postalCode" label="Postal code" />
          <FunctionField render={record => <span>{formatUTCDate(record.startDate)}</span>} label="Start date" />
          <FunctionField render={record => <span>{formatUTCDate(record.endDate)}</span>} label="End date" />
          <NumberField source="availableBags" />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
        </SimpleForm>
      </FormTab>
      <FormTab label="Deliveries">
      </FormTab>
    </TabbedForm>
  </Edit>
);

const VanSlots = () => {
  return (
    <Resource
      name="shipments/time"
      options={{ label: 'Van slots' }}
      list={VanSlotsList}
      edit={VanSlotsEdit}
      show={VanSlotsShow}
      create={VanSlotsCreate}
    />
  );
}

export default VanSlots;
