import {
  Edit,
  SimpleForm,
  NumberField,
  NumberInput,
  BooleanInput,
} from 'react-admin';

export const BagEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <NumberField source="total" />
      <NumberField source="available" />
      <NumberInput source="price" />
      <NumberInput source="bagsUpdate" label="Add / remove bags" />
      <BooleanInput source="updateAvailable" label="Update only available?" />
    </SimpleForm>
  </Edit>
);
