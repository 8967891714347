import {
  BooleanField,
  CreateButton,
  Datagrid,
  ExportButton,
  List,
  NumberField,
  TextField,
  TopToolbar,
  usePermissions,
  DateField,
  EditButton,
  ReferenceField,
} from 'react-admin';

const PaymentMethodsListActions = () => {
  const { permissions } = usePermissions();

  return (
    <TopToolbar>
      <CreateButton />
      {permissions === 'SUPER_ADMIN' && (<ExportButton maxResults={99999999} />)}
    </TopToolbar>
  );
};

const Disclaimer = () => (
  <div style={{ padding: '1em', backgroundColor: '#f9f9f9', marginBottom: '1em', borderRadius: '4px', borderLeft: '5px solid #ccc', width: '15%', textAlign: 'center' }}>
    <span style={{ fontSize: '5em', color: '#FFCC00', display: 'block' }}>⚠️</span>
    <div>
      <h4>Disclaimer</h4>
      <p>Only make edits on this page if you know what you are doing!</p>
    </div>
  </div>
);

export const PaymentMethodsList = props => {
  const { permissions } = usePermissions();

  return (
    <List
      actions={<PaymentMethodsListActions />}
      perPage={25}
      sort={{ field: 'name', order: 'ASC' }}
      aside={<Disclaimer />}
      {...props}
    >
      <Datagrid rowClick={false}>
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceField
          label="Shipment Type"
          source="shipmentTypeId"
          reference="shipments/types"
          alwaysOn
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="imageName" />
        {permissions === 'SUPER_ADMIN' && (<EditButton label="Edit" variant="contained"/>)}
        <NumberField source="price" locales="el-GR" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} />
        <NumberField source="priceDiscounted" locales="el-GR" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} />
        <BooleanField source="active" />
        <BooleanField source="onSpecialOffer" />
        <DateField source="createdAt" showTime label="Created" />
        <DateField source="updatedAt" showTime label="Updated" />
      </Datagrid>
    </List>
  )
};