import {
  authUrl,
  getHeaders
} from '../utils';

const authProvider = {
  login: ({ username, password }) => {
    return fetch(`${authUrl}/users/admin/signin`, {
      method: 'POST',
      body: JSON.stringify({ email: username, password }),
      headers: getHeaders(),
    })
      .then(res => {
        if (res.status < 200 || res.status >= 300) {
          throw new Error('Error');
        }
        return res.json();
      })
      .then(res => {
        localStorage.setItem('token', res.jwt);
      })
      .catch(() => {
        localStorage.setItem('token', '');
        throw new Error('Network error');
      });
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
  },
  logout: () => {
    localStorage.removeItem('token');
    return Promise.resolve();
  },
  getIdentity: () => {
    return fetch(`${authUrl}/users/me`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => {
        if (res.status < 200 || res.status >= 300) {
          return Promise.reject({ redirectTo: '/login' });
        }
        return res.json();
      })
      .then(res => {
        res.fullName = res.firstName;
        res.avatar = res.profileImage;
        localStorage.setItem('user', JSON.stringify(res));
        return res;
      })
      .catch(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        const loginUrl = `${window.origin}/#/login`;
        if (window.location.href !== loginUrl) {
          window.location.replace(loginUrl);
        }
        return Promise.reject({ redirectTo: loginUrl });
      });
  },
  getPermissions: (params) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const roles = user?.roles;
    if (roles.includes("ROLE_SUPER_ADMIN")) {
      return Promise.resolve("SUPER_ADMIN");
    } else {
      return Promise.resolve("ADMIN");
    }
  },
  sendVerificationEmail: email => {
    return fetch(`${authUrl}/contact/email-verification`, {
      method: 'POST',
      body: JSON.stringify({ email }),
      headers: getHeaders(),
    });
  },
};

export default authProvider;