
import { useState, useEffect } from 'react';
import { Button, useDataProvider } from 'react-admin';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import JSConfetti from 'js-confetti';

import styles from './pickups.module.scss';

const PickupCountWidget = () => {

  const dataProvider = useDataProvider();
  const [pickupCount, setPickupCount] = useState(0);
  const jsConfetti = new JSConfetti();

  useEffect(() => {
    const GetPickupsCount = async () => {
      try {
        const res = await dataProvider.getPickupsNo();
        setPickupCount(res?.total);
      } catch (error) {
        console.warn('Error: ', error);
      }
    };

    GetPickupsCount();
  }, [dataProvider]); // Dependency array to avoid infinite loop

  return (
    <div className={styles.widgetContainer}>
      <Gauge
        width={250}
        height={150}
        value={pickupCount}
        valueMax={120}
        startAngle={-90}
        endAngle={90}
        sx={{
            [`& .${gaugeClasses.valueText}`]: {
              fontSize: 40,
              transform: 'translate(0px, 0px)',
            }
        }}
        text={({ value, valueMax }) => `${value} / ${valueMax}`}
      />
      {pickupCount === 0 && <Button onClick={() => jsConfetti.addConfetti()}>🎉Celebrate!🎉</Button>}
      {pickupCount === 0 && <Button onClick={() => jsConfetti.addConfetti({emojis: ['👚','👗','👖','📚','👜','🥋','👞','🎩','👒','👛' ]})}>🎉Celebrate with clothes!🎉</Button>}
    </div>
  );
};

export default PickupCountWidget;
