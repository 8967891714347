import React from 'react';
import { Title } from 'react-admin';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Widget from 'src/common/widget/widget';
import YearlySalesWidget from './yearlySalesWidget';

import styles from 'src/Dashboard/styles.module.scss';
import MonthlySalesWidget from './monthlySalesWidget';


const Sales = () => {
  return (
    <Card>
      <Title title="Sales Analytics" />
      <CardContent>
        <span className={styles.widgetsTitle}>Sales</span>
        <div className={styles.widgetsRow}>
          <Widget title="Sales" subtitle={"Number of items sold"}>
            {YearlySalesWidget()}
            {MonthlySalesWidget()}
          </Widget>
        </div>
      </CardContent>
    </Card>
  );
};

export default Sales;