import {
  Show,
  SimpleShowLayout,
  NumberField,
} from 'react-admin';

export const BagShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <NumberField source="total" />
      <NumberField source="available" />
      <NumberField source="price" />
    </SimpleShowLayout>
  </Show>
);
